/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component } from "react";
import { errorPageContainer, errorPageDesc, errorPageTitle } from "../../styles/errorPageStyles";
const styles = {
    fontSize: "120px",
    lineHeight: "1.6"
};
class NotFound extends Component {
    render() {
        return (<div css={css(errorPageContainer)}>
				<span css={css(errorPageTitle)}>Nah, the page you are looking for doesn't exist.</span>
				<h1 css={styles}>404</h1>
				<p css={css(errorPageDesc)}>
					Either we messed up or you typed a wrong address. Nevertheless, let's go <a href="/">home</a>, it isn't safe
					out here.
				</p>
			</div>);
    }
}
export default NotFound;
