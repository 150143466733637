import { keyframes, css } from "@emotion/core";
import { colors } from "./styles";
import { mediaFeatures } from "../../../../styles/common";
export const getFont = (fontSize, weight, lineheight, spacing, fontColor) => `
    font-size: ${fontSize};
    font-weight: ${weight ? weight : 500};
    line-height: ${lineheight ? lineheight : '133%'};
    letter-spacing: ${spacing ? lineheight : 'normal'};
	${fontColor ? `color: ${colors[fontColor]}` : ""};
`;
export const ellipsis = (maxWidth) => `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${maxWidth && ` max-width: ${maxWidth};`}
`;
export const ellipsisLine = (lines) => `
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: ${lines || "2"};
	-webkit-box-orient: vertical;
`;
// font-color util
export const FontColor = (color) => `
    color: ${colors[color]};
`;
// background-color util
export const BackgroundColor = (color) => `
    background: ${colors[color]};
`;
export const flexBox = (justifyContent, alignItems, flexWrap, direction) => `
	display: flex;
	justify-content: ${justifyContent ? justifyContent : "center"};
	align-items: ${alignItems ? alignItems : "center"};
	flex-wrap: ${flexWrap ? flexWrap : "unset"};
    flex-direction: ${direction ? direction : "row"};
`;
export const dimensions = (height, width) => `
    height: ${height};
    width: ${width};
`;
// setting border box
export const borderBox = (onlySelf) => `
    ${onlySelf
    ? ` 
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    `
    : `
        &, & * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
    `}
`;
// skeletion/shimmer loader
export const skeletonLoading = `
    ${BackgroundColor('--grey-dark-copy')}
	display: inline-block;
	height: 16px;
	position: relative;
	overflow: hidden;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			${colors["--Colors-Neutral-Grey-300"]} 0,
			${colors["--Colors-Neutral-Grey-50"]} 20%,
			${colors["--Colors-Neutral-Grey-300"]} 60%,
			${colors["--Colors-Neutral-Grey-50"]}
		);
		animation: shimmer 3s infinite;
		content: "";
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
`;
export const TextUnderlineDashed = (color) => `
	border-bottom: 1px dashed ${color ? color : "#98989F"};
    width: fit-content;
`;
export const FixedFooter = `
	${BackgroundColor('--Background-BG-White')}
	border-top: 1px solid ${colors["--Colors-Transparency-White-100-T8"]};
	box-shadow: 0px -20px 44px 0px ${colors["--Colors-Transparency-Black-200-T72"]};
	backdrop-filter: blur(50px);
    position: fixed;
    bottom: 0;
	left: 0;
	padding: 16px;
	padding-bottom: calc(var(--safe-area-inset-bottom, 0px) + 16px);
    z-index: 2;
	width: 100%;
`;
export const stickyBlurBackground = `
    position: sticky;
    top: 0;
    backdrop-filter: blur(5px);
`;
export const ModalSeperator = `
	width: 100%;
	height: 0.5px;
	border: 0.5px solid ${colors["--Colors-Neutral-Grey-700"]};
`;
// Form utils
export const FormFieldErrorShake = `
	color: ${colors["--Colors-Red-300"]};
	display: block;
	margin-top: 6px;
	animation: shake .5s cubic-bezier(.5,0,.5,1) both;
	@keyframes shake {
		10%, 90% {
			transform: translate(-2px, 0);
		}
		20%, 80% {
			transform: translate(2px, 0);
		}
		30%, 50%, 70% {
			transform: translate(-5px, 0);
		}
		40%, 60% {
			transform: translate(5px, 0);
		}
	}
	&:not(:empty) + * {
		margin-top: 16px;
	}
`;
export const shimmer = keyframes `
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
`;
export const shimmerGradient = css `
    background: linear-gradient(
        to right,
        transparent 35%,
        rgba(255, 255, 255, 0.08) 42%,
        rgba(255, 255, 255, 0.08) 48%,
        transparent 55%
    );
	background-size: 200% 100%;
    background-color: ${colors['--Colors-Neutral-Grey-700']};
	animation: ${shimmer} 3s infinite linear;
`;
export const titleDefaultStyle = `
	${getFont('21px', 700)}
    ${FontColor('--grey-highlight-copy')}
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		${getFont('16px', 700)}
	}
`;
