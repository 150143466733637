/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState } from "react";
import { Body, colors } from "../../Atoms/Typography/styles";
import { FontColor, dimensions, ellipsisLine, flexBox, getFont } from "../../Atoms/Typography/stylesUtils";
const LineupItem = (props) => {
    const { img, title, subTitle, extraText, limit = 80, showReadMore = false, doEllipsis = true, contStyle } = props;
    const [showAll, setShowAll] = useState(false);
    let shortSubtitle = subTitle;
    let shouldShorten = false;
    if (showReadMore) {
        shortSubtitle = (subTitle !== null && subTitle !== void 0 ? subTitle : '').slice(0, limit);
        shouldShorten = (subTitle !== null && subTitle !== void 0 ? subTitle : '').length > limit;
        if (shouldShorten)
            shortSubtitle += `...`;
    }
    return (<div css={css([flexBoxCont(!!subTitle), contStyle])}>
            <img src={img} alt="lineup artist"/>
            <div css={css([iconTextContentCont])}>
                <p css={css([iconTextTitle])}>{title}</p>
                {extraText && <p css={css([iconTextExtraSubTitle])}>{extraText}</p>}
                {subTitle && <p css={css([iconTextSubTitle(doEllipsis)])}>
                    {showAll ? subTitle : shortSubtitle} 
                    {shouldShorten &&
                <span onClick={() => setShowAll(!showAll)} css={css([readMoreStyle])}>
                            {showAll ? "Read less" : "Read more"}
                        </span>}
                </p>}
            </div>
        </div>);
};
export default LineupItem;
const flexBoxCont = (isSubTitle) => `
    ${isSubTitle ? flexBox('flex-start', 'center') : flexBox('flex-start', 'flex-start')}
    gap: 16px;
    width: 100%;
    ${Body}
    svg, img {
        ${dimensions('90px', '90px')}
        border-radius: 12px;
    }
`;
const iconTextContentCont = `
    flex: 1;
`;
const iconTextTitle = `
    ${getFont('14px', 700)}
    ${FontColor('--grey-highlight-copy')}
`;
const iconTextExtraSubTitle = `
    ${getFont('12px', 500)}
    ${FontColor('--grey-highlight-copy')}
    margin: 4px 0;
`;
const iconTextSubTitle = (doEllipsis) => `
    ${getFont('12px', 400)}
    ${FontColor('--grey-regular-copy')}
    ${doEllipsis ? ellipsisLine('2') : ''}
`;
const readMoreStyle = `
    ${getFont('12px', 500)}
    ${FontColor('--grey-regular-copy')}
    border-bottom: 1px dashed ${colors['--grey-dark-copy']};
    width: min-content;
    margin-left: 4px;
`;
