/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { headerYoutube, headerYoutubeActionContainer, headerYoutubeTitle, videoHeaderDetail, videoHeaderSummaryStyles, videoVisualCard, } from "../styles/modules/headerCards";
import GenreStrip from "./GenreStrip";
const VideoSummaryCard = (props) => {
    const { headerVisual, headerTitle, headerSummary, categoryName, categoryColour, title, summary } = props;
    return (<section css={css(headerYoutube)} className="header">
			<div css={css(videoHeaderDetail)}>
				<h1 css={css(headerYoutubeTitle)} className={headerTitle}>{title}</h1>
				<div css={css(headerYoutubeActionContainer)}>
					<div css={css(videoHeaderSummaryStyles)} className={headerSummary} dangerouslySetInnerHTML={{ __html: summary }}/>
					{props.actionButton}
				</div>
			</div>
			<div css={css(videoVisualCard)} className={headerVisual}>
				{props.headerImageOrVideo}
				{!props.isFromApp && <GenreStrip categoryName={categoryName} categoryColour={categoryColour}/>}
			</div>
			{props.shareButtons}
		</section>);
};
VideoSummaryCard.defaultProps = {
    categoryColour: ""
};
export default VideoSummaryCard;
