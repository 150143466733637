import React, { Component } from "react";
import ShareButtonsRow from "../ShareButtonsRow";
import ShareButton from "../ShareButton";
import Favourite from "../../containers/FavouriteWithSubscription";
import { InsiderLogoImage } from "../../helpers/constants";
class SummaryCardShare extends Component {
    constructor() {
        super(...arguments);
        this.showFavouriteCount = (count, isFavourited) => {
            if (count) {
                return `${count} favourites`;
            }
            else {
                return isFavourited ? `Favourited` : `Favourite`;
            }
        };
    }
    render() {
        const modifierClass = "isEdpMobile";
        const { shareText, shareUrl, headerShareClass, slug, _id, name, showFavourite, imageUrl, isEDP, isFromApp } = this.props;
        if (shareText && shareUrl) {
            return (<div className={headerShareClass}>
					<ShareButtonsRow text={shareText} url={shareUrl} position="page-top" modifierClass={"isEdpDesktop"} hideCloseButton={true}/>
					<ShareButton text={shareText} url={shareUrl} imageUrl={imageUrl || InsiderLogoImage} position="page-top" headerShareClass={headerShareClass} name={name} slug={slug} isEDP={isEDP} isFromApp={isFromApp}/>
					{showFavourite && (<Favourite slug={slug} _id={_id} modifierClass={modifierClass} showButton={false} showFavouriteCount={this.showFavouriteCount}/>)}
				</div>);
        }
        return <div className={headerShareClass}/>;
    }
}
SummaryCardShare.defaultProps = {
    isSPA: true
};
export default SummaryCardShare;
