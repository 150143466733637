/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as React from "react";
import theme from "../../styles/common";
import NewShareIcon from "../ui/icons/NewShareIcon";
import { flexBox } from "../ui-lib/Atoms/Typography/stylesUtils";
const shareTitleStyle = `
	font-size: 14px;
	font-weight: 700;
`;
const shareSubTitleStyle = `
	font-size: 12px;
	color: #555D6D;
	font-weight: 400;
	margin-top: 4px;
`;
const EDPShareDiv = `
	${flexBox('flex-start', 'center')}
	gap: 12px;
`;
const shareButtons = `
${theme.mediaQueries["for-pc"]} {
    // position: absolute; 
    right: 0;
	display: flex;
	justify-content: flex-end;
	width: 68px;
	height: 28px;
}`;
const mobileShareStyle = `
	// width:88vw;
	margin:auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	padding: 15px;
	width: 100%;
	justify-content: space-between;
	border-radius: 16px;
	background: #F3F3F5;
	margin: 30px 0;
`;
const EDPShare = (props) => {
    return (<React.Fragment>
			<div css={css(mobileShareStyle)}>
				<div css={css(EDPShareDiv)}>
					<NewShareIcon />
					<div>
						<p css={css(shareTitleStyle)}>Invite your friends</p>
						<p css={css(shareSubTitleStyle)}>and enjoy a shared experience</p>
					</div>
				</div>
				<div css={css(shareButtons)}>{props.showShareButtons}</div>
			</div>
			{props.seperator}
		</React.Fragment>);
};
export default EDPShare;
