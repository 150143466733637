import { connect } from "react-redux";
import { isFromApp } from "../selectors/index";
import ActiveOffersWithCopy from "../components/ActiveOffersWithCopy";
import { withRouter } from "react-router";
const mapStateToProps = (state, ownProps) => ({
    activeOffers: ownProps.activeOffers,
    isFromApp: isFromApp(state),
    queryParams: ownProps.location.query
});
const ActiveOffersWithSubscription = withRouter(connect(mapStateToProps, null)(ActiveOffersWithCopy));
export default ActiveOffersWithSubscription;
