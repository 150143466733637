/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { browserHistory } from "react-router";
import { SeatNotAvailableIcon } from "../ui/icons/SeatNotAvailableIcon";
import { BackgroundColor, BaseBold, FontColor, SmallMedium, Uppercase, XSmall, XSmallMedium, colors } from "../ui-lib/Atoms/Typography/styles";
import CartIcon from "../ui/icons/CartIcon";
import { groupBy, isEmpty } from "lodash";
import { TicketSoldOut } from "../ui/icons/TicketSoldOut";
import queryString from "query-string";
import BottomSheetModal from "../BottomSheetModal";
import { PrimaryButton } from "../ui-lib/Atoms/Buttons/styles";
import { DirectionIcon } from "../ui/icons/CheckoutV2/DirectionIcon";
import { Fragment } from "react";
import { isFromApp } from "../../helpers/misc";
export const FixedElementHeight = {
    header: "60px",
    mobileHeader: "56px",
    buyFlowheader: "87.61px",
    buyFlowFooter: "69px",
    mobileBuyFlowheader: "72.61px",
    mobileBuyFlowFooter: "99px",
    paytmBannerHeight: "58px"
};
const queryParams = window && window.location.search;
export const navigateToShowsListPage = (slug) => browserHistory.push(`/event/${slug}/buy-page/shows${queryParams}`);
export const SeatsNotAvailableIconWithBg = () => (<div css={css(IconContainer, BackgroundColor("--Comm-Error-Light"))}>
		<SeatNotAvailableIcon />
	</div>);
export const AddToCartErrorIconWithBg = () => (<div css={css(IconContainer, BackgroundColor("--Comm-Error-Light"))}>
		<CartIcon width="38" height="39" fill={colors["--Comm-Negative"]}/>
	</div>);
export const TicketSoldOutIconWithBg = () => (<div css={css(IconContainer, BackgroundColor("--Comm-Error-Light"))}>
		<TicketSoldOut />
	</div>);
export const handleBackButton = (path) => {
    window.onpopstate = () => {
        browserHistory.push(path);
    };
};
export const TicketsAtVenueModal = ({ onDismiss, venue = {} }) => {
    const { name: venueName = "", address: venueAddress = "", geolocation: { latitude: venueLatitude = 0, longitude: venueLongitude = 0 } } = venue;
    return (<BottomSheetModal handleDismiss={() => {
            onDismiss();
        }}>
			<div css={css(Container)}>
				<TicketSoldOutIconWithBg />
				<div css={css(VenueDetailsContainer)}>
					<p css={css([BaseBold, FontColor("--grey-highlight-copy"), marginTitle])}>{`Tickets for this event are no longer available online`}</p>
					<div css={css(VenueDetails)}>
						<p css={css([SmallMedium, FontColor("--grey-regular-copy")])}>{`Head to the venue to get your tickets!`}</p>
						<div css={css([VenueContainer, !!(venueLatitude || venueLongitude) && `gap: 9px;`])}>
							<p css={css([SmallMedium, FontColor("--grey-highlight-copy")])}>{venueName}</p>
							{venueAddress && <p css={css([XSmall, FontColor("--grey-regular-copy")])}>{venueAddress}</p>}
							{!!(venueLatitude || venueLongitude) ? (<a css={css(DirectionsButton)} href={`//maps.google.com/?q=${venueLatitude},${venueLongitude}`}>
									<DirectionIcon />
									<p>Get directions</p>
								</a>) : (<p css={css([XSmall, FontColor("--grey-regular-copy")])}>Check again later.</p>)}
						</div>
						<p css={css([XSmallMedium, FontColor("--Grey-Small-Copy")])}>* Tickets are subject to availability</p>
					</div>
				</div>
				{!isFromApp() && (<Fragment>
						<div css={css(ModalSeperator)}/>
						<button css={css([PrimaryButton, `margin-top: 15px;`, Uppercase])} onClick={() => {
                const queryParams = window && window.location.search;
                window.location.href = `/${queryParams || ""}`;
            }}>
							Explore Other events
						</button>
					</Fragment>)}
			</div>
		</BottomSheetModal>);
};
// SVG functions
const addClass = (el, className) => {
    el && el.classList && el.classList.add(className);
};
const removeClass = (el, className) => {
    el && el.classList && el.classList.remove(className);
};
const addClassToNodes = (nodeList, className) => {
    Array.from(nodeList).forEach(node => {
        addClass(node, className);
    });
};
const removeClassFromNodes = (nodeList, className) => {
    Array.from(nodeList).forEach(node => {
        removeClass(node, className);
    });
};
const markSectionUnavailable = (name) => {
    name = name.replace(/'/g, "\\'");
    const section = document.querySelectorAll(`[data-id='${name}']`);
    removeClassFromNodes(section, "section-seatmap");
    const label = document.querySelectorAll(`[data-id='${name}'] > .section-label`);
    addClassToNodes(label, "sold-out-label");
    const background = document.querySelectorAll(`[data-id='${name}'] > .section-background`);
    addClassToNodes(background, "sold-out-area");
};
export const markSectionAvailable = (name) => {
    name = name.replace(/'/g, "\\'");
    const section = document.querySelectorAll(`[data-id='${name}']`);
    addClassToNodes(section, "section-seatmap");
    const label = document.querySelectorAll(`[data-id='${name}'] > .section-label`);
    removeClassFromNodes(label, "sold-out-label");
    const background = document.querySelectorAll(`[data-id='${name}'] > .section-background`);
    removeClassFromNodes(background, "sold-out-area");
};
export const markAllSectionsUnavailable = (currentShow) => {
    const items_with_group_names = generateItemsWithGroupNames(currentShow);
    items_with_group_names.forEach(item => {
        markSectionUnavailable(item.group_name);
    });
};
const markAllSectionsAvailable = (currentShow) => {
    const items_with_group_names = generateItemsWithGroupNames(currentShow);
    items_with_group_names.forEach(item => {
        markSectionAvailable(item.group_name);
    });
};
export const disableSoldOutSections = (currentShow) => {
    try {
        markAllSectionsAvailable(currentShow);
        currentShow.items_for_sale.forEach((itemGroup) => {
            const group = itemGroup;
            const isSoldOut = group.sold_out ||
                group.items.every((item) => item.is_hidden ||
                    item.item_state !== "available" ||
                    item.quantity_available_for_purchase <= 0 ||
                    (item.restrictions.indexOf("*") === -1 && item.restrictions.indexOf("online") === -1));
            if (isSoldOut) {
                markSectionUnavailable(group.name);
            }
        });
    }
    catch (error) {
        console.error(error);
    }
};
const generateItemsWithGroupNames = (currentShow) => {
    let items_with_group_names = [];
    currentShow.items_for_sale.forEach(itemGroup => {
        let available_items = [];
        const group = itemGroup;
        if (!group.sold_out) {
            available_items = group.items.filter((item) => !item.is_hidden &&
                item.item_state === "available" &&
                item.quantity_available_for_purchase > 0 &&
                (item.restrictions.indexOf("*") >= 0 || item.restrictions.indexOf("online") >= 0));
        }
        available_items.forEach((item) => {
            let result = {};
            result.group_name = group.name;
            result.name = item.name;
            result.price = item.price;
            result.id = item._id;
            if (item.metadata) {
                result.metadata = item.metadata;
            }
            items_with_group_names.push(result);
        });
    });
    return items_with_group_names;
};
export const generatePriceGroups = (currentShow) => {
    let items_with_group_names = generateItemsWithGroupNames(currentShow);
    let priceGroups = groupBy(items_with_group_names, item => item.price);
    const uniquePriceGroups = {};
    Object.keys(priceGroups).forEach(price => {
        uniquePriceGroups[price] = new Set(priceGroups[price].map(item => item.group_name));
    });
    Object.keys(uniquePriceGroups).forEach(price => {
        priceGroups[price] = Array.from(uniquePriceGroups[price]);
    });
    return priceGroups;
};
export const generateMetadataGroups = (currentShow) => {
    let items_with_group_names = generateItemsWithGroupNames(currentShow);
    let metaDataGroups = {};
    items_with_group_names.forEach(item => {
        if (item.metadata && item.metadata.svg_filters) {
            item.metadata.svg_filters.forEach((filter) => {
                if (!metaDataGroups[filter]) {
                    metaDataGroups[filter] = [];
                }
                metaDataGroups[filter].push(item.group_name);
            });
        }
    });
    const uniqueMetaDataGroups = {};
    Object.keys(metaDataGroups).forEach(metadata => {
        uniqueMetaDataGroups[metadata] = new Set(metaDataGroups[metadata]);
    });
    Object.keys(uniqueMetaDataGroups).forEach(metadata => {
        metaDataGroups[metadata] = Array.from(uniqueMetaDataGroups[metadata]);
    });
    return metaDataGroups;
};
// CT common params
export const getCommmonCTParams = (eventData, userProfile, isLoggedIn) => {
    var _a, _b, _c;
    const params = {};
    if (!isEmpty(eventData)) {
        const event = eventData.toJS();
        params["Event Name"] = event === null || event === void 0 ? void 0 : event.name;
        params["Event Slug"] = event === null || event === void 0 ? void 0 : event.slug;
        params["Event ID"] = event === null || event === void 0 ? void 0 : event._id;
        params["Event Category"] = (_a = event === null || event === void 0 ? void 0 : event.category_id) === null || _a === void 0 ? void 0 : _a._id;
        params["Event Group"] = (_b = event === null || event === void 0 ? void 0 : event.group_id) === null || _b === void 0 ? void 0 : _b._id;
        params["Event Brand"] = (_c = event === null || event === void 0 ? void 0 : event.brand_id) === null || _c === void 0 ? void 0 : _c._id;
    }
    if (isLoggedIn) {
        const user = userProfile.toJS();
        params["User ID"] = user === null || user === void 0 ? void 0 : user._id;
        params["User Email"] = user === null || user === void 0 ? void 0 : user.email;
        params["apiKey"] = user === null || user === void 0 ? void 0 : user.apiKey;
    }
    if (window && window.location.search) {
        const query = queryString.parse(window.location.search);
        if (query.utm_source) {
            params["utm_source"] = query.utm_source;
        }
        if (query.utm_content) {
            params["utm_content"] = query.utm_content;
        }
        if (query.utm_medium) {
            params["utm_medium"] = query.utm_medium;
        }
    }
    return params;
};
// styles
const IconContainer = `
	display: flex;
	padding: 5px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 12px;
	width: fit-content;
`;
export const OverrideItemCard = `
	width: 100%;
	max-width: 600px;
	margin: 0;
`;
const Container = `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 15px;
	flex: 1 0 0;
`;
const marginTitle = `
		margin: 4px 0;
		width: 90%;
`;
const VenueContainer = `
	display: flex;
	padding: 15px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 15px;
	border: 0.5px solid #E7E8EA;
	background: #F9F9FA;
`;
const DirectionsButton = `
	display: flex;
	min-width: 72px;
	padding: 6px 9px 6px 6px;
	justify-content: center;
	align-items: center;
	gap: 3px;
	align-self: stretch;
	border-radius: 12px;
	border: 0.5px solid #E7E8EA;
	background: #FFF;
`;
const ModalSeperator = `
	width: 100%;
	height: 0.5px;
	border: 0.5px solid ${colors["--grey-borders-dividers"]};
	${BackgroundColor("--grey-borders-dividers")}
`;
const VenueDetailsContainer = `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 15px;
	flex: 1 0 0;
	width: 100%;
`;
const VenueDetails = `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
`;
