import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { openInBrowserForApp } from "../helpers/appBridge";
import { pushWindow } from "../helpers/h5";
const ActiveOffersWithCopy = props => {
    let { activeOffers, isFromApp, queryParams: { platform, version } } = props;
    const shouldOpenLinkOnApp = url => {
        if (url.includes("/event") || url.includes("/article") || url.includes("/go")) {
            return true;
        }
        return false;
    };
    const onClick = (event, mappingLink) => {
        if (isFromApp) {
            event.preventDefault();
            if (shouldOpenLinkOnApp(mappingLink)) {
                const isFirstParam = !mappingLink.includes("?");
                const urlQueryParams = `platform=${platform}${version ? `&version=${version}` : ``}`;
                const linkWithParams = `${mappingLink}${isFirstParam ? "?" : "&"}${urlQueryParams}`;
                pushWindow(linkWithParams);
            }
            else {
                openInBrowserForApp(mappingLink);
            }
        }
    };
    return (<div className="active-discount">
			{activeOffers.map(activeOffers => {
            // this is a hack to hide the copy button for the 0BOOKINGFEE offer, to be removed once the offer is over
            const showCopyButton = activeOffers.get("couponCode") !== "0BOOKINGFEE";
            return (<div className="active-discount-box" key={activeOffers.get("couponCode")}>
						<div className="active-discount-body">
							<p className="active-discount-title">{activeOffers.get("discountTitle")}</p>
							<p>{activeOffers.get("discountBody")}</p>
							{activeOffers.get("linkCTA") && activeOffers.get("mappingLink") && (<a className="active-discount-link" href={activeOffers.get("mappingLink")} target="_blank" onClick={e => onClick(e, activeOffers.get("mappingLink"))}>
									{activeOffers.get("linkCTA")}
								</a>)}
						</div>
						{showCopyButton && (<CopyToClipboard text={activeOffers.get("couponCode")} onCopy={() => props.onCopyToClipboard(activeOffers.get("couponCode"))}>
								<button className="active-discount-copy">COPY</button>
							</CopyToClipboard>)}
					</div>);
        })}
		</div>);
};
export default ActiveOffersWithCopy;
