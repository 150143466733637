import { clearfix } from "../base/clearfix";
import { container, narrowContainer } from "../base/container";
import { columns } from "../base/functions";
import { span } from "../base/gridHelper";
import { gutterMargin } from "../base/margin";
import { bodyFontSizes, colors, InterFont, mediaFeatures, medPadding } from "../common";
export const imageCaptionCSS = `
	${bodyFontSizes}
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0,0.5);
	padding: ${medPadding};
	color: ${colors["white"]};
	font-family: ${InterFont};
	font-weight: normal;
	white-space: normal;
`;
export const imageContainer = `
    ${container}
    ${gutterMargin("top")}
    ${gutterMargin("bottom")}
    position: relative;
    &::after {
        clear: none;
        display: none;
    }
    img, video {
        display: inline;
        max-width: 100%;
        vertical-align: bottom;
    }

    &.image-size-small {
        img, video, figcaption {
            ${span(4, 2, 4, 4)}
        }
    }

    &.image-size-medium {
        img, video, figcaption {
            ${span(4, 4, 6, 6)}
        }
    }

    &.image-size-big {
        ${narrowContainer}
        img, video, figcaption {
            width: 100%;
        }
    }

    &.image-size-large {
        img, video, figcaption {
            width: 100%;
        }
    }

    &.image-size-stretch {
        width: 100%;
        img, video, figcaption {
            width: 100%;
        }
    }

    &.image-align-left {
        img, video {
            float: left;
        }
        &.image-wrap-text {
            img, video {
                ${gutterMargin("right")}
                ${gutterMargin("bottom")}
            }
        }
        &.image-wrap-column {
            img, video {
                ${gutterMargin("right")}
                ${gutterMargin("bottom")}
            }
            @media screen and ${mediaFeatures["for-pc"]} {
                img, video {
                    margin-bottom: 0;
                }
            }
        }
        &.image-size-big {
            @media screen and ${mediaFeatures["for-pc"]} {
                margin-left: 10vw;
            }
        }
    }

    &.image-align-right {
        img, video {
            float: right;
        }
        &.image-size-small {
            figcaption {
                left: auto;
                right: 0;
            }
        }
        &.image-size-medium {
            figcaption {
                left: auto;
                right: 0;
            }
        }

        &.image-wrap-text {
            img, video {
                ${gutterMargin("left")}
                ${gutterMargin("bottom")}
            }
        }
        &.image-wrap-column {
            img, video {
                ${gutterMargin("left")}
                ${gutterMargin("bottom")}
            }
            @media screen and ${mediaFeatures["for-pc"]} {
                img, video {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.image-align-center {
        img, video {
            display: block;
            margin: 0 auto;
            float: none;    
        }
        @media screen and ${mediaFeatures["for-tablet"]} {
            &.image-size-small {
                figcaption {
                    left: ${columns("tablet", 2, "wide")}vw;
                }
            }
            &.image-size-medium {
                figcaption {
                    left: ${columns("tablet", 1, "wide")}vw;
                }
            }
        }
        @media screen and ${mediaFeatures["for-pc"]} {
            &.image-size-small {
                figcaption {
                    left: ${columns("pc", 4, "wide")}vw;
                }
            }
            &.image-size-medium {
                figcaption {
                    left: ${columns("pc", 3, "wide")}vw;
                }
            }
        }
        @media screen and ${mediaFeatures["for-hd"]} {
            &.image-size-small {
                figcaption {
                    left: ${columns("hd", 4, "wide")}px;
                }
            }
            &.image-size-medium {
                figcaption {
                    left: ${columns("hd", 4, "wide")}px;
                }
            }
        }
    }

    &.image-wrap-false {
        ${clearfix}
        & + * {
            clear: both;
        }
    }

    &.image-wrap-text {
        & + .text {
            margin-top: 0;
            ${clearfix}
        }
        &.image-size-medium{
            & + .text {
                ${container}
            }
        }
    }

    &.image-wrap-column {
        & + .text {
            margin-top: 0;
            ${clearfix}
        }
        &.image-size-medium {
            & + .text {
                ${container}
            }
        }
    }
`;
