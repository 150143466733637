import { bigMargin } from "./base/margin";
import { bigPadding } from "./base/padding";
import { bannerFontSizes, bodyRead, colors, mediaFeatures, stdDashedBorder } from "./common";
import { splitColors } from "./splitColors";
export const errorPageContainer = `
    ${bigMargin()}
    ${bigPadding()}
    ${stdDashedBorder}
`;
export const errorPageTitle = `
    ${bannerFontSizes}
    ${bigMargin("bottom")}
    display: block;
    line-height: 1.5;
`;
export const errorPageImage = `
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    ${bigMargin("bottom")}
    img {
        width: 100%;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        width: 40%;
	}
`;
export const errorPageDesc = `
    ${bodyRead}
    font-weight: normal;
    line-height: 1.7;
    margin: 0 auto;
    width: 65vw;
    a {
        text-decoration: none;
        color: ${splitColors["insiderBlue"]};
    }
    @media screen and ${mediaFeatures["for-tablet"]} {
        width: 55vw;
	}
    @media screen and ${mediaFeatures["for-pc"]} {
        width: 31.875vw;
	}
    @media screen and ${mediaFeatures["for-hd"]} {
        width: 510px;
	}
`;
