/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState } from "react";
import { BaseBold, Body, FontColor, XSmall, colors, flexBox } from "../Typography/styles";
import { ArrowDownIcon } from "../../../ui/icons/ArrowDownIcon";
import { ArrowUpIcon } from "../../../ui/icons/ArrowUpIcon";
const Accordion = (props) => {
    const { title, subTitle, children, opened, contStyles } = props;
    const [isOpened, setIsOpened] = useState(opened !== null && opened !== void 0 ? opened : false);
    return (<div css={css([AccordionCont, contStyles])}>
            <div css={css([AccordionHeadingCont(isOpened)])} onClick={() => setIsOpened(!isOpened)}>
                <div>
                    <p css={css([AccordionHeadingTitle])}>{title}</p>
                    {subTitle && <p css={css([AccordionHeadingSub])}>{subTitle}</p>}
                </div>
                {isOpened ? <ArrowUpIcon overideStyle={{ height: '24px', width: '24px', padding: '4px', flexShrink: '0' }}/> : <ArrowDownIcon overideStyle={{ height: '24px', width: '24px', padding: '4px', flexShrink: '0' }}/>}
            </div>
            {isOpened && children}
        </div>);
};
export default Accordion;
const AccordionCont = `
    margin: 15px 0;
`;
const AccordionHeadingCont = (isOpened) => `
    padding: 12px;
    ${flexBox('space-between', 'center')}
    ${Body}
    cursor: pointer;
    ${isOpened ? `border-bottom: 1px solid ${colors['--grey-borders-dividers']};` : ''}
`;
const AccordionHeadingTitle = `
    ${BaseBold}
    ${FontColor('--grey-highlight-copy')}
`;
const AccordionHeadingSub = `
    ${XSmall}
`;
