import { mediaFeatures } from "../common";
import { hdSideGutter, pcSideGutter } from "./variables";
export const textMarginSmall = (customSideGutterPc = "", customSideGutterHd = "") => `
    @media screen and ${mediaFeatures["for-pc"]} {
        margin-left: ${customSideGutterPc ? customSideGutterPc : `${pcSideGutter}vw`} !important;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        margin-left: ${customSideGutterHd ? customSideGutterHd : hdSideGutter} !important;
    }
`;
