/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment } from "react";
import classNames from "classnames";
import { mediaFeatures } from "../styles/common";
import { standaloneVisual, visualCard } from "../styles/modules/headerCards";
const headerVisualStyle = `
	overflow: hidden;
    
	${visualCard}
	img {
		border-radius: 12px;
	}
	@media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
		img {
			border-radius: 0;
		}
	}
`;
const SummaryCardVisual = (props) => {
    return (<Fragment>
			<div className={classNames(props.shellClass)} css={css([headerVisualStyle, props.standalone && standaloneVisual])}>
				{props.headerImageOrVideo}
			</div>
			{props.monumentsBanner}
		</Fragment>);
};
SummaryCardVisual.defaultProps = {
    isSPA: true,
    standalone: false,
    monumentsBanner: <Fragment />
};
export default SummaryCardVisual;
