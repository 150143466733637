import { bigHd, bigMobile, bigPc, bigTablet, mediaFeatures } from "../common";
import { gutter } from "./functions";
export const smallMargin = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigMobile} / 2);
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigTablet} / 2);
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigPc} / 2);
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigHd} / 2);
    }
`;
export const bigMargin = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin${direction ? `-${direction}` : ""}: ${bigMobile};
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        margin${direction ? `-${direction}` : ""}: ${bigTablet};
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        margin${direction ? `-${direction}` : ""}: ${bigPc};
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        margin${direction ? `-${direction}` : ""}: ${bigHd};
    }
`;
export const largeMargin = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigMobile} * 1.5);
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigTablet} * 1.5);
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigPc} * 1.5);
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        margin${direction ? `-${direction}` : ""}: calc(${bigHd} * 1.5);
    }
`;
export const gutterMargin = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin${direction ? `-${direction}` : ""}: ${gutter("mobile")}vw;
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        margin${direction ? `-${direction}` : ""}: ${gutter("tablet")}vw;
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        margin${direction ? `-${direction}` : ""}: ${gutter("pc")}vw;
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        margin${direction ? `-${direction}` : ""}: ${gutter("hd")}px;
    }
`;
