/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Fragment } from "react";
import { ShareIcon } from "./ShareIcon";
import ShareButtonsRow from "./ShareButtonsRow";
import { sendClevertapEvent, nativeShare } from "../helpers/misc";
import { CLEVERTAP_ACTIONS, GENERAL_ACTIONS, HIT_TYPES, EVENT_CATEGORIES, GA_RECOMMENDED_EVENTS, addGAEvent } from "../helpers/constants";
import theme from "../styles/common";
import { DATA_REFS } from "../helpers/constants/dataRefs";
const sendEdpShareFallbackEvent = (platform, url, eventName) => {
    const payload = {
        platform,
        url,
        eventName
    };
    sendClevertapEvent(CLEVERTAP_ACTIONS.EDP_SHARE_PILL_FALLBACK, payload);
};
const inviteButtonStyle = `
	border-radius: 9px;
	font-size: 14px;
	display: flex;
	padding: 9px 15px;
	align-items: center;
	border: 1px solid #E7E8EA;
	background: #FCFCFD;
	font-weight: 600;
	color: #242E44;
	${theme.mediaQueries["for-tablet"]}{
		display: none;
	}
`;
class ShareButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onSuccess = () => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.EDP_SHARE_PILL_CLICKED, {
                succeeded: true,
                name: this.props.name,
                url: this.props.url
            });
        };
        this.onError = () => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.EDP_SHARE_PILL_CLICKED, {
                succeeded: false,
                name: this.props.name,
                url: this.props.url
            });
        };
        this.handleDismiss = () => {
            this.setState({ showFallback: false });
        };
        this.onShare = () => {
            window.ga &&
                ga(GENERAL_ACTIONS.SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: EVENT_CATEGORIES.SHARE,
                    eventAction: this.props.slug
                });
            addGAEvent(GA_RECOMMENDED_EVENTS.SHARE, {
                content_type: this.props.slug
            });
            const { showFallback } = this.state;
            if (showFallback) {
                this.setState({ showFallback: false });
            }
            const { url, text, imageUrl } = this.props;
            const fallback = () => {
                this.setState({ showFallback: true });
            };
            nativeShare({ text, url, imageUrl, onSuccess: this.onSuccess, onError: this.onError, fallback });
        };
        this.state = {
            showFallback: false
        };
    }
    renderFallback() {
        const { showFallback } = this.state;
        const { text, url } = this.props;
        if (showFallback) {
            return (<div className={`social-mobile-edp-strip page bottom enabled`}>
					<ShareButtonsRow name={name} text={text} url={url} position="page-top" modifierClass={"from-pill"} handleDismiss={this.handleDismiss} sendEdpShareFallbackEvent={sendEdpShareFallbackEvent} isFromApp={this.props.isFromApp}/>
				</div>);
        }
    }
    render() {
        const { text, url, isEDP } = this.props;
        if (!text || !url) {
            return null;
        }
        return (<Fragment>
				{isEDP ? (<div onClick={this.onShare} css={css([inviteButtonStyle])} data-ref={DATA_REFS.EDP_SHARE_BUTTON}>
						SHARE
					</div>) : (<div className="icon-share-pill" onClick={this.onShare} data-ref={DATA_REFS.EDP_SHARE_ICON}>
						<div className={"share-icon"}>
							<ShareIcon />
						</div>
						<div className={"icon-share-text"}>Share</div>
					</div>)}
				{this.renderFallback()}
			</Fragment>);
    }
}
export default ShareButton;
