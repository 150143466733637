/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useMemo, useState } from "react";
import { Body, FontColor, flexBox, XSmallMedium, SmallSemiBold, colors } from "../../ui-lib/Atoms/Typography/styles";
import NotAllowedIcon from "./icons/NotAllowedIcon";
import RichTextTitle from "../../RichTextTitle";
import { dimensions } from "../../ui-lib/Atoms/Typography/stylesUtils";
import { ArrowDownIcon } from "../../ui/icons/ArrowDownIcon";
import { ArrowUpIcon } from "../../ui/icons/ArrowUpIcon";
const ProhabitedList = (props) => {
    const { list, limitItem = 12, title = "Prohabited items", subTitle = "Following list of prohibited items are not allowed to carry in this event" } = props;
    const showViewAll = list.length > limitItem;
    const [viewAll, setViewAll] = useState(false);
    const getItem = useMemo(() => {
        if (showViewAll) {
            return viewAll ? list : list.slice(0, limitItem);
        }
        else
            return list;
    }, [showViewAll, viewAll]);
    return (list.length > 0 && <React.Fragment>
            <RichTextTitle text={title}/>
            <div css={css([cont(showViewAll)])}>
                {getItem.map((item, idx) => {
            var _a, _b;
            const iconUrl = (_a = item === null || item === void 0 ? void 0 : item.icon_url) !== null && _a !== void 0 ? _a : '';
            const itemText = (_b = item === null || item === void 0 ? void 0 : item.heading) !== null && _b !== void 0 ? _b : '';
            return (<div css={css([listItem])} key={`prohabited.item_${idx}`}>
                            {iconUrl ? <img src={iconUrl} alt="icon"/> :
                    <NotAllowedIcon />}
                            <p>{itemText}</p>
                        </div>);
        })}
            </div>
            {showViewAll && <div css={css([viewAllCont])} onClick={() => setViewAll(!viewAll)}>
                <p>{!viewAll ? 'View all' : 'Collapse'}</p>
                {!viewAll ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </div>}
        </React.Fragment>);
};
export default ProhabitedList;
const cont = (showViewAll) => `
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: ${showViewAll ? '24px 0' : '24px 0 0'};
`;
const listItem = `
    ${flexBox('flex-start', 'flex-start')}
    width: 150px;
    gap: 4px;
    p {
        ${XSmallMedium}
        ${Body}
        ${FontColor('--grey-regular-copy')}
    }
    svg , img {
        height: 16px;
        width: 16px;
        flex-shrink: 0;
    }
`;
const viewAllCont = `
    ${flexBox('center', 'center')}
    gap: 8px;
    padding: 16px 0;
    border-bottom: 1px solid ${colors['--grey-borders-dividers']};
    img, svg {
        ${dimensions('16px', '16px')}
        flex-shrink: 0;
    }
    p {
        ${SmallSemiBold}
        ${Body}
        ${FontColor('--grey-highlight-copy')}
    } 
`;
