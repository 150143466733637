import { createSelector } from "reselect";
import { List, fromJS } from "immutable";
import moment from "moment";
import { generateDigitalEntryPDFLink } from "../helpers/misc";
const cancellable_policies = ["cancellable", "protected"];
// Reselect
export const getShortcode = (_, props) => props.shortcode;
export const getTransactionId = (_, props) => (props.transactionId ? props.transactionId : props.params.transactionId);
export const getTickets = state => state.getIn(["tickets", "data"], []);
export const getEventTickets = createSelector([getTickets], tickets => {
    return tickets.filter(ticket => ticket.get("event_type") !== "pass");
});
export const getPassTickets = createSelector([getTickets], tickets => {
    return tickets.filter(ticket => ticket.get("event_type") === "pass");
});
export const getTicketsForTransactionId = createSelector([getTickets, getTransactionId], (tickets, transactionId) => {
    return tickets.filter(ticket => ticket.get("transaction_id") === transactionId);
});
export const getEventForTransaction = createSelector([getTicketsForTransactionId, getTransactionId], (tickets, transactionId) => {
    return tickets.getIn([0, "events", 0]);
});
export const getAllTicketsForShortcode = createSelector([getTickets, getShortcode], (tickets, shortcode) => {
    const transaction_id = tickets.filter(ticket => ticket.get("shortcode") === shortcode).getIn(0, "transaction_id");
    if (transaction_id) {
        return tickets.filter(ticket => ticket.get("transaction_id") === "transaction_id");
    }
    return new List([]);
});
export const getTicketFromShortcode = createSelector([getTickets, getShortcode], (tickets, shortcode) => {
    return tickets.filter(ticket => ticket.get("shortcode") === shortcode).get(0);
});
export const getTicketDataFromShortcode = state => fromJS(state.getIn(["tickets", "ticketData"], []));
export const getTicketDataForShortcode = createSelector([getTicketFromShortcode, getTicketDataFromShortcode], (ticket, ticketData) => {
    if (ticket)
        return ticket;
    return ticketData;
});
export const getItemsForTransaction = createSelector([getTicketDataForShortcode], ticket => {
    let result = new List([]);
    const events = ticket.get("events", []);
    const inventories = events.map(event => {
        const items = event.get("items", []);
        result = result.concat(items);
    });
    return result.map(item => {
        return item.withMutations(item => {
            // Seated items have a seated array instead of tickets
            const ticketsOrSeats = item.get("tickets") || item.get("seats", []);
            const cancellable = ticketsOrSeats.filter(ticket => cancellable_policies.includes(ticket.get("cancellation_policy"))).size > 0;
            return item.set("cancellable", cancellable);
        });
    });
});
export const getCancellableItemsForTransaction = createSelector([getItemsForTransaction], items => {
    return items.filter(item => item.get("cancellable"));
});
export const getIsCancelledForShortcode = createSelector([getItemsForTransaction], items => {
    let soldInventories = new List([]);
    items.map(item => {
        const tickets = item.get("tickets", []);
        soldInventories = soldInventories.concat(tickets);
    });
    const nonCancelled = soldInventories.filter(inventory => inventory.get("status") !== "cancelled");
    return !(nonCancelled.length > 0);
});
export const getCancellableSoldInventoriesForTransaction = createSelector([getItemsForTransaction], items => {
    let soldInventories = new List([]);
    items.map(item => {
        const ticketsOrSeats = item.get("tickets") || item.get("seats", []);
        soldInventories = soldInventories.concat(ticketsOrSeats);
    });
    soldInventories = soldInventories.filter(item => {
        if (item.get("type") === "physical") {
            return item.get("status") === "booked";
        }
        return ["booked", "delivered"].includes(item.get("status"));
    });
    const output = soldInventories.filter(item => cancellable_policies.includes(item.get("cancellation_policy")));
    const atleastOneProtected = soldInventories.some(item => item.get("cancellation_policy") === "protected");
    if (!atleastOneProtected) {
        return new List([]);
    }
    return output;
});
export const getSoldInventoryIdsForTransaction = createSelector([getCancellableSoldInventoriesForTransaction], items => {
    return items.map(inventory => inventory.get("_id"));
});
export const getIsCancellableForShortcode = createSelector([getCancellableSoldInventoriesForTransaction], ids => {
    return ids.size > 0;
});
export const getTicketDataReceived = state => {
    return state.getIn(["tickets", "ticketDataReceived"]);
};
export const getHasMoreTickets = state => {
    return state.getIn(["tickets", "hasMoreTickets"]);
};
export const getCurrentPageForTickets = state => {
    return state.getIn(["tickets", "currentPage"]);
};
export const getLoadingMore = state => {
    return state.getIn(["tickets", "loadingMoreTickets"]);
};
export const getTicketPurchasedStatus = state => {
    return state.getIn(["tickets", "ticketPurchasedStatus"]);
};
export const getTicketPurchasedStatusReceived = state => {
    return state.getIn(["tickets", "ticketPurchasedStatusReceived"]);
};
export const getTicketDataErrored = state => {
    return state.getIn(["tickets", "ticketDataErrored"]);
};
export const ticketDetailDataReceived = state => {
    return state.getIn(["tickets", "ticketDetailDataReceived"]);
};
export const getOriginalCostForTransaction = createSelector([getCancellableItemsForTransaction], items => {
    return items.map(item => item.get("originalCost", 0)).reduce((a, b) => a + b, 0);
});
export const getDeliveryChargesForTransaction = createSelector([getCancellableItemsForTransaction], items => {
    return items.map(item => item.get("delivery_charges", 0)).reduce((a, b) => a + b, 0);
});
export const getDiscountForTransaction = createSelector([getCancellableItemsForTransaction], items => {
    return items.map(item => item.get("discount"), 0).reduce((a, b) => a + b, 0);
});
export const getTaxesForTransaction = createSelector([getCancellableItemsForTransaction], items => {
    const taxesPerItem = items.map(item => {
        const taxes = item
            .get("taxes", [])
            .map(tax => tax.get("amount", 0))
            .reduce((a, b) => a + b, 0);
        return taxes;
    });
    return taxesPerItem.reduce((a, b) => a + b, 0);
});
export const getRefundAmountFromTransaction = createSelector([getOriginalCostForTransaction, getDeliveryChargesForTransaction, getDiscountForTransaction, getTaxesForTransaction], (originalCost, deliveryCharges, discount, taxes) => {
    return originalCost + taxes + deliveryCharges - discount;
});
export const getCancellableFromShortcode = createSelector([getTicketFromShortcode], ticket => {
    let cancellable = false;
    const events = ticket.get("events", []);
    events.map(event => {
        const items = event.get("items", []);
        items.map(item => {
            const ticketsOrSeats = item.get("tickets") || item.get("seats", []);
            cancellable =
                cancellable ||
                    ticketsOrSeats.some(ticket => cancellable_policies.includes(ticket.get("cancellation_policy")));
        });
    });
    return cancellable;
});
export const getCancellationDeadline = createSelector([getItemsForTransaction], items => {
    let deadlines = new List([]);
    items.map(item => {
        const ticketsOrSeats = (item && item.get("tickets")) || (item && item.get("seats", []));
        ticketsOrSeats.map(ticket => {
            deadlines = deadlines.concat(ticket.get("cancellation_deadline", 0));
        });
    });
    return deadlines && deadlines.filter(deadline => deadline > 0).get(0, 0);
});
export const getDigitalEntryTicketUuid = createSelector([getTicketDataForShortcode], ticket => {
    // The uuid will be same for all tickets with the shortcode
    const item = ticket.getIn(["events", 0, "items", 0]);
    const ticketsOrSeats = (item && item.get("tickets")) || (item && item.get("seats", []));
    return ticketsOrSeats && ticketsOrSeats.getIn([0, "digital_ticket_name"]);
});
export const getDigitalEntryTicketUrl = createSelector([getDigitalEntryTicketUuid], uuid => {
    return generateDigitalEntryPDFLink(uuid);
});
export const getDeliveryDetails = createSelector([getTicketFromShortcode], ticket => {
    return ticket.get("delivery_details");
});
export const getPickupInformation = createSelector([getTicketDataForShortcode], ticket => {
    return ticket.get("pickup_information");
});
export const getStatus = createSelector([getItemsForTransaction], items => {
    let statuses = new List([]);
    items.map(item => {
        const ticketsOrSeats = item.get("tickets") || item.get("seats", []);
        ticketsOrSeats.map(ticket => {
            const type = ticket.get("item_type");
            let status = ticket.get("status", "");
            if (type === "digital" && status === "delivered") {
                status = "confirmed";
            }
            statuses = statuses.concat(status);
        });
    });
    return statuses.filter(status => status).get(0, "");
});
export const getItemType = createSelector([getItemsForTransaction], items => {
    let types = new List([]);
    items.map(item => {
        const ticketsOrSeats = item.get("tickets") || item.get("seats", []);
        ticketsOrSeats.map(ticket => {
            const type = ticket.get("item_type");
            types = types.concat(type);
        });
    });
    return types.filter(type => type).get(0, "");
});
export const getZoomLink = createSelector([getTicketDataForShortcode], ticket => {
    //Zoom details will be same for all tickets of a shortcode
    const item = ticket.getIn(["events", 0, "items", 0]);
    const ticketsOrSeats = (item && item.get("tickets")) || (item && item.get("seats", []));
    return ticketsOrSeats && ticketsOrSeats.getIn([0, "encoded_zoom_join_url"]);
});
export const getNgageEventUrl = createSelector([getTicketDataForShortcode], ticket => {
    const item = ticket.getIn(["events", 0, "items", 0]);
    const ticketsOrSeats = (item && item.get("tickets")) || (item && item.get("seats", []));
    return ticketsOrSeats && ticketsOrSeats.getIn([0, "join_event_url"]);
});
export const checkVOD = createSelector([getTicketDataForShortcode], ticket => {
    return ticket.getIn(["events", 0, "items", 0, "tickets", 0, "show_type"]) === "vod";
});
export const checkIfPast = createSelector([getTicketDataForShortcode, checkVOD], (ticket, isVODTicket) => {
    const eventTimeLeftStatus = ticket.getIn(["events", 0, "timeLeft", "status"]);
    let isPastEvent = eventTimeLeftStatus === "past";
    // Check for VOD
    if (isVODTicket) {
        isPastEvent = false;
        const limitedValidity = ticket.get("validity_end_timestamp");
        if (limitedValidity) {
            // SoldInventory skips validity_end_timestamp for unlimited validity
            // Such event should always be valid and allowed to join
            try {
                isPastEvent = new Date() > new Date(limitedValidity.toString());
            }
            catch (e) {
                // fallback to false value
                isPastEvent = false;
            }
        }
    }
    return isPastEvent;
});
const getISOTimestamp = ticket => {
    const newDate = ticket.getIn(["events", 0, "shows", 0, "show_start_utc_timestamp"]);
    const date = moment.unix(newDate).toISOString();
    return date;
};
// to filter out all the upcoming events
// and sort them to get the one most upcoming event.
export const getMostUpcomingEvent = createSelector([getTickets], tickets => {
    const currentDate = new Date().toISOString();
    if (tickets.size > 1) {
        const upcomingEvents = tickets
            .filter(ticket => getISOTimestamp(ticket) > currentDate)
            .sort((a, b) => (getISOTimestamp(a) > getISOTimestamp(b) ? 1 : -1));
        return upcomingEvents.get(0);
    }
});
export const checkIfJoiningLinkExists = createSelector([getZoomLink, getNgageEventUrl], (zoomLink, engageEventUrl) => {
    const isJoiningLinkUnavailable = zoomLink === undefined && engageEventUrl === undefined;
    return isJoiningLinkUnavailable;
});
