import React from "react";
import { DATA_REFS } from "../helpers/constants/dataRefs";
import { headerVideoStyles, headerYoutubeStyles } from "../styles/modules/headerCards";
import Image from "./Image";
import VideoEmbed from "./VideoEmbed";
const REGULAR_SUMMARY_IMAGE_WIDTH = 1280;
const REGULAR_SUMMARY_IMAGE_WIDTHS = [640, 768, 1024, 1280];
const REGULAR_SUMMARY_IMAGE_STRING = "(max-width:35em) 640px, (max-width:48em) 768px, (max-width:64em) 1024px, 1280px";
const LARGE_SUMMARY_IMAGE_WIDTH = 1920;
const LARGE_SUMMARY_IMAGE_WIDTHS = [640, 1024, 1440, 1920];
const LARGE_SUMMARY_IMAGE_STRING = "(max-width:35em) 640px, (max-width:48em) 1024px, (max-width:64em) 1440px, 1920px";
const SummaryCardHeader = (props) => {
    // for shell
    if (!props.headerImage) {
        return null;
    }
    // for header video
    if (props.headerVideo && props.headerType !== "large") {
        const headerVideoCSS = props.headerType === "youtube" ? headerYoutubeStyles : headerVideoStyles;
        return (<VideoEmbed embedSrc={props.headerVideo} videoClassName="header-video" imgWidth={REGULAR_SUMMARY_IMAGE_WIDTH} imgWidths={REGULAR_SUMMARY_IMAGE_WIDTHS} sizeString={REGULAR_SUMMARY_IMAGE_STRING} headerVideoCSS={headerVideoCSS}/>);
    }
    if (props.headerType === "large" || props.headerType === "youtube") {
        return (<Image alt={props.title} src={props.headerImage} imgWidth={LARGE_SUMMARY_IMAGE_WIDTH} imgWidths={LARGE_SUMMARY_IMAGE_WIDTHS} sizeString={LARGE_SUMMARY_IMAGE_STRING} lazy={false}/>);
    }
    // default - load image
    return (<Image alt={props.title} src={props.headerImage} imgWidth={REGULAR_SUMMARY_IMAGE_WIDTH} imgWidths={REGULAR_SUMMARY_IMAGE_WIDTHS} sizeString={REGULAR_SUMMARY_IMAGE_STRING} lazy={false} dataRef={DATA_REFS.EDP_EVENT_BANNER_IMAGE}/>);
};
SummaryCardHeader.defaultProps = {
    headerType: "regular"
};
export default SummaryCardHeader;
