/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState } from "react";
import { ChevronRight } from "../../../ui/icons/ChevronRight";
import LineupItem from "../LineupItem";
import { colors } from "../../Atoms/Typography/styles";
import { FontColor, dimensions, flexBox, getFont, titleDefaultStyle } from "../../Atoms/Typography/stylesUtils";
import BottomSheet from "../../../BottomSheetModal";
const LineupList = (props) => {
    const { title = 'Lineup', castList, showViewAll = true, itemLimit = 3 } = props;
    const [showAllItems, setShowAllItems] = useState(false);
    const updatedCastList = showViewAll ? castList.slice(0, itemLimit) : castList;
    return (<React.Fragment>
            <div css={css([titleCont])}>
			    <h6 css={css([titleDefaultStyle])}>{title}</h6>
                {showViewAll &&
            <div css={css([showViewAllCont])} onClick={() => setShowAllItems(true)}>
                    <p>See all</p>
                    <ChevronRight fill={colors['--grey-highlight-copy']}/>
                </div>}
            </div>
            <div css={css([castCon])}>
                {updatedCastList.map((cast, idx) => (<LineupItem key={`cast.name_${idx}`} img={cast.imgUrl} title={cast.name} extraText={cast.extraText} subTitle={cast.description} showReadMore={false}/>))}
            </div>
            {showAllItems && <BottomSheet handleDismiss={() => setShowAllItems(false)}>
                <div css={css([titleCont])}>
                    <h6 css={css([titleDefaultStyle])}>{title}</h6>
                </div>
                <div css={css([castCon, castConBottom, maxheightCont])}>
                    {castList.map((cast, idx) => (<LineupItem key={`bottom.cast.name_${idx}`} img={cast.imgUrl} title={cast.name} extraText={cast.extraText} subTitle={cast.description} doEllipsis={false}/>))}
                </div>
			</BottomSheet>}
        </React.Fragment>);
};
export default LineupList;
const titleCont = `
    ${flexBox('space-between', 'center')}
    gap: 16px;
    h6 {
        margin-bottom: 20px;
    }
`;
const showViewAllCont = `
    ${flexBox('flex-start', 'center')}
    gap: 4px;
    p {
        ${getFont('14px', 600)}
        ${FontColor('--grey-highlight-copy')}
    }
    svg, img {
        ${dimensions("16px", "16px")}
    }
   	&:hover {
       	cursor: pointer;
    }
`;
const castCon = `
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const castConBottom = `
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > div {
        border-bottom: 1px solid ${colors['--Colors-Neutral-Grey-700']};
        padding: 16px 0;
        &:last-child {
            border-bottom: none;
        }
    }
`;
const maxheightCont = `
    max-height: 70vh;
    overflow-y: scroll;
`;
