import { connect } from "react-redux";
import EventPromoCodeBanner from "../components/EventPromoCodeBanner";
import { setNotifications, clearNotifications } from "../actions/index";
const mapDispatchToProps = (dispatch) => {
    return {
        notify: (notification) => dispatch(setNotifications(notification)),
        clearNotifications: () => dispatch(clearNotifications())
    };
};
const EventPromoCodeBannerWithSubscription = connect(null, mapDispatchToProps)(EventPromoCodeBanner);
export default EventPromoCodeBannerWithSubscription;
