import { connect } from "react-redux";
import { fetchDiscountActiveOffers } from "../actions/index";
import ActiveOffersContainer from "../components/ActiveOffersContainer/index";
import { setNotifications, clearNotifications } from "../actions/index";
import { getEventDiscountOfferBySlug, getOfferConditionByEventSlug, getEventNameBySlug } from "../selectors/activeDiscountOffers";
import { getEventSlug } from "../selectors";
const mapStateToProps = (state, props) => {
    return {
        offerConditions: getOfferConditionByEventSlug(state, props.eventSlug),
        offers: getEventDiscountOfferBySlug(state, props.eventSlug),
        eventName: getEventNameBySlug(state, props.eventSlug),
        eventSlug: getEventSlug(state, { eventSlug: props.eventSlug })
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchGetDiscountOffer: (eventSlug, data) => dispatch(fetchDiscountActiveOffers({
        meta: { eventSlug: eventSlug },
        offerConditions: data
    })),
    notify: notification => dispatch(setNotifications(notification)),
    clearNotifications: () => dispatch(clearNotifications())
});
const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
    return Object.assign(Object.assign(Object.assign({}, propsFromState), propsFromDispatch), { getDiscountOffer: () => propsFromDispatch.dispatchGetDiscountOffer(ownProps.eventSlug, propsFromState.offerConditions) });
};
const EventDiscountOffers = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ActiveOffersContainer);
export default EventDiscountOffers;
