import { colors, mediaFeatures, superbannerFontSizes } from "../common";
export const heroImage = `
    height: 50vh;
    min-height: 380px;
    overflow: hidden;
    text-align: center;
    background: ${colors["royal-gray"]};
    img {
        vertical-align: bottom;
        width: auto;
        height: 100%;
        transform: translate(calc(-1*(100% - 100vw)/2),0);
    }
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: auto;
    }
	@media screen and ${mediaFeatures["for-hd"]} {
        img{
            width: 100%;
            height: auto;
        }
    }
`;
export const heroImageLazyWrapper = `
    opacity: 0.5;
`;
export const heroName = `
    ${superbannerFontSizes}
    font-weight: bold;
    margin: 0;
`;
