import { container, narrowContainer } from "../base/container";
import { columns, containerWidth, gutter, narrowContainerWidth } from "../base/functions";
import { span } from "../base/gridHelper";
import { gutterMargin, smallMargin } from "../base/margin";
import { gutterPadding } from "../base/padding";
import { textMarginSmall } from "../base/textHelpers";
import { aspectRatio, mobileSideGutter, stdBoxShadow, tabletGutterWidth, tabletSideGutter } from "../base/variables";
import { topInGroup } from "../base/zIndex";
import { bannerFontSizes, colors, mediaFeatures, medPadding, stdPadding, subbannerFontSizes, titleFontSizes } from "../common";
import { bigFontSize } from "../typography";
import { heroImage, heroName } from "./hero";
export const visualCard = `
    ${span(4, 4.2, 8, 8, "single", 1 / aspectRatio, "normal", "none")}
    display: inline-block;
    figure, img, video {
        width: 100%;
        min-height: 100%;
    }
    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        width: 100vw;
        height: ${100 / aspectRatio}vw;
    }
`;
const headerVideoLargeTabletHeight = `calc(${columns("tablet", 4)}vw / ${aspectRatio})`;
const headerVideoPc = `calc(${narrowContainerWidth("pc")}vw / ${aspectRatio})`;
const headerVideoHd = `calc(${narrowContainerWidth("hd")}px / ${aspectRatio})`;
export const headerVideoStyles = `
    height: calc(100vw / ${aspectRatio});
    img {
        transform: translate(0,calc(-1*(100% - (100vw / ${aspectRatio}))/2));
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        height: ${headerVideoLargeTabletHeight};
        img {
            transform: translate(0,calc(-1*(100% - ${headerVideoLargeTabletHeight})/2));
        }
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        height: ${headerVideoPc};
        img {
            transform: translate(0,calc(-1*(100% - ${headerVideoPc})/2));
        }
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        height: ${headerVideoHd};
        img {
            transform: translate(0,calc(-1*(100% - ${headerVideoHd})/2));
        }
    }
`;
export const visualCardHeaderSocial = `
    display: none;
`;
export const visualCardHeaderSocialActionable = `
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        display: block;
        right: ${stdPadding};
        bottom: ${stdPadding};
    }
`;
export const socialButton = `
    box-shadow: ${stdBoxShadow};
`;
export const standaloneVisual = `
    ${visualCard}
    ${textMarginSmall()}
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-left: ${tabletGutterWidth}vw;
    }
`;
export const detailCard = `
    ${gutterMargin("left")}
    ${span(4, 2.4896, 4, 4, "single", false, "normal", "none")}
    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        margin: 0 auto;
    }
    @media screen and ${mediaFeatures["for-small-tablet-only"]} {
        width: ${columns("tablet", 6)}vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        position: relative;
        display: inline-block;
        vertical-align: top;
        height: ${columns("tablet", 4.2) / aspectRatio}vw;
        overflow: hidden;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        height: ${narrowContainerWidth("pc") / aspectRatio}vw;
        padding-bottom: initial;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        padding-bottom: initial;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        height: ${narrowContainerWidth("hd") / aspectRatio}px;
        padding-bottom: initial;
    }
`;
export const headerTitle = `
    ${subbannerFontSizes}
	@media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        font-size: 20px;
    }
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-top: -1 * ${medPadding}/3;
    }
`;
export const detailCardHeaderSocial = `
    margin-left: -${mobileSideGutter}vw;
	@media screen and ${mediaFeatures["for-small-tablet-only"]} {
        margin-left: -${tabletSideGutter}vw;
    }
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-left: 0;
        bottom: ${gutter("tablet")}vw;
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        bottom: ${gutter("pc")}vw;
    }
	@media screen and ${mediaFeatures["for-hd"]} {
        bottom: ${gutter("hd")}px;
    }
`;
export const detailCardHeaderSocialActionable = `
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        display: none;
    }
`;
export const headerSummaryStyles = `
    margin-top: ${medPadding};
    color: #676465;
    font-weight: 400;
    line-height: 1.7;
    ${titleFontSizes}
	@media screen and ${mediaFeatures["for-tablet"]} {
        overflow: hidden;
        max-height: ${1.55 * 1.75 * 5}vw;
        text-overflow: ellipsis;
        @supports (-webkit-line-clamp: 6) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
        }
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        max-height: ${1.2 * 1.75 * 5}vw;
    }
	@media screen and ${mediaFeatures["for-large-pc"]} {
        margin-top: calc(${stdPadding} * 1.25);
        max-height: ${1.11 * 1.75 * 6}vw;
        @supports (-webkit-line-clamp: 6) {
            -webkit-line-clamp: 6;
        }
    }
	@media screen and ${mediaFeatures["for-hd"]} {
        max-height: calc(${bigFontSize} * 1.75 * 6);
    }
`;
export const headerActionButtonWrapper = `
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        display: flex;
        width: auto;
    }
	@media screen and ${mediaFeatures["for-hd"]} {
        left: ${gutter("hd")}px;
        right: ${gutter("hd")}px;
        bottom: ${gutter("hd")}px;
    }
`;
export const standaloneDetail = `
    ${detailCard}
    margin-bottom: $mobile-side-gutter + vw;
    overflow: visible !important;
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-bottom: 0;
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        @supports (position: sticky) or (position:-webkit-sticky){
            position: sticky;
            position: -webkit-sticky;
            // background-color: ${colors["white"]};
            z-index: ${topInGroup};
            height: auto;
        }
    }
	@media screen and ${mediaFeatures["for-mobile"]} {
        margin-bottom: 2vw;
    }
`;
export const largeVisualCard = `
    ${heroImage}
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 60vw;
    min-height: 150px;
    background-color: ${colors["black"]};
    figure {
        height: 100%;
    }
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        height: calc((100vw / ${aspectRatio}) - 4px);
        position: static;
        img {
            width: 100%;
            height: auto;
            transform: translate(calc(-1*(100% - 100vw)/2),calc(-1*(100% - (100vw / ${aspectRatio}))/2));
        }
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        height: 82vh;
        img {
            transform: translate(calc(-1*(100% - 100vw)/2),calc(-1*(100% - 82vh)/2));
        }
    }
	@media screen and ${mediaFeatures["for-large-pc"]} {
        height: 86vh;
        img {
            transform: translate(calc(-1*(100% - 100vw)/2),calc(-1*(100% - 86vh)/2));
        }
    }
`;
export const largeVisualCardLazyWrapper = `
    opacity: 1;
`;
export const largeHeaderDetailContainer = `
    position: relative;
	@media screen and ${mediaFeatures["for-large-tablet"]} {
        width: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.55);
        bottom: 0;
        color: ${colors["white"]};
    }
`;
export const largeHeaderDetailContainerNoOverlay = `
    background-color: transparent;
`;
export const largeHeaderDetail = `
    ${container}
    ${gutterPadding("top")}
    ${gutterPadding("bottom")}
	@media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        padding: ${mobileSideGutter}vw 0;
    }
    .card-genre {
        top: -32px;
	    @media screen and ${mediaFeatures["for-large-tablet"]} {
            position: static;
        }
    }
`;
export const largeHeaderSummaryStyles = `
    ${narrowContainer}
    margin-left: 0;
    margin-top: 16px !important;
    color: ${colors["white"]};
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin-top: 12px !important;
        color: ${colors["black"]};
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-right: auto;
    }
`;
export const largeHeaderTitle = `
    ${heroName}
    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        letter-spacing: -0.25px;
        font-size: 25px;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-top: ${medPadding};
        letter-spacing: 0;
    }
`;
export const videoHeaderSummaryStyles = `
    font-weight: 400;
    line-height: 1.7;
    ${titleFontSizes}
    color: ${colors["black"]};
    margin-top: 0;
    ${narrowContainer}
    @media screen and ${mediaFeatures["for-large-tablet-only"]} {
        width: ${columns("tablet", 4.3)}vw;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-right: auto;
        margin-left: 0;
    }
`;
export const videoHeaderDetail = `
    ${container}
    ${smallMargin("bottom")}
`;
export const videoVisualCard = `
    ${container}
    ${gutterMargin("bottom")}
    border-radius: 2px;
    min-height: 100px;
    img, video {
        width: 100%;
        display: block;
    }
    overflow: hidden;
    position: relative;
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-bottom: 0;
        min-height: 200px;
    }
`;
export const headerYoutube = `
    ${gutterMargin("top")}
    ${container}
    @media screen and ${mediaFeatures["for-mobile"]} {
        margin-top: ${mobileSideGutter}vw;
    }
    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        width: 100%;
    }
`;
export const headerYoutubeTitle = `
    ${bannerFontSizes}
    font-weight: 700;
    line-height: 1.38;
    margin-bottom: calc(${stdPadding} / 2);
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        margin-bottom: ${medPadding};
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        margin-bottom: ${stdPadding};
    }
`;
export const headerYoutubeActionContainer = `
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        display: flex;
        align-items: flex-end;
    }
`;
const headerYoutubeHeight = `${containerWidth("mobile") / aspectRatio}vw`;
const headerYoutubeTabletHeight = `${containerWidth("tablet") / aspectRatio}vw`;
const headerYoutubePc = `${columns("pc", 9.25) / aspectRatio}vw`;
const headerYoutubeHd = `${columns("hd", 9.25) / aspectRatio}px`;
export const headerYoutubeStyles = `
    height: ${headerYoutubeHeight};
    figure {
        transform: translate(0,calc(-1*(100% - (${headerYoutubeHeight}))/2));
    }
    @media screen and ${mediaFeatures["for-tablet"]} {
        height: ${headerYoutubeTabletHeight};
        figure {
            transform: translate(0,calc(-1*(100% - (${headerYoutubeTabletHeight}))/2));
        }
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        height: ${headerYoutubePc};
        figure {
            margin: 0 auto;
            width: ${columns("pc", 9.25)}vw;
            transform: translate(0,calc(-1*(100% - ${headerYoutubePc})/2));
        }
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        height: ${headerYoutubeHd};
        figure {
            width: ${columns("hd", 9.25)}px;
            transform: translate(0,calc(-1*(100% - ${headerYoutubeHd})/2));
        }
    }
`;
