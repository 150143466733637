/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState } from "react";
import Artist from '../Artist';
import { ChevronRight } from "../../../ui/icons/ChevronRight";
import { colors, flexBox } from "../../Atoms/Typography/styles";
import { titleDefaultStyle } from "../../Atoms/Typography/stylesUtils";
const ArtistList = ({ castList, title }) => {
    var _a, _b, _c;
    const [showAllItems, setShowAllItems] = useState(false);
    return (<React.Fragment>
        <div css={css([titleCont])}>
            <h6 css={css([titleDefaultStyle])}>{title}</h6>
        </div>
        {(castList === null || castList === void 0 ? void 0 : castList.length) && (castList === null || castList === void 0 ? void 0 : castList.length) > 1 &&
            <div css={css([castCon])}>
            {castList.map((cast, idx) => (<Artist key={`cast.name_${idx}`} cast={cast} onShowAllClick={() => setShowAllItems(true)}/>))}
            </div>}
        {(castList === null || castList === void 0 ? void 0 : castList.length) && (castList === null || castList === void 0 ? void 0 : castList.length) === 1 &&
            <div css={css([castCon])}>
                <Artist 
            // key={`cast.name_${idx}`} 
            cast={{
                    name: (_a = castList === null || castList === void 0 ? void 0 : castList[0]) === null || _a === void 0 ? void 0 : _a.name,
                    imageUrl: (_b = castList === null || castList === void 0 ? void 0 : castList[0]) === null || _b === void 0 ? void 0 : _b.imageUrl,
                    description: <p css={css([flexBox('flex-start', 'center'), 'margin-top: 7.5px'])}><span>About artist </span> <ChevronRight fill={colors['--grey-dark-copy']}/> </p>,
                    href: (_c = castList === null || castList === void 0 ? void 0 : castList[0]) === null || _c === void 0 ? void 0 : _c.href
                }} rowView onShowAllClick={() => { var _a; return !!((_a = castList === null || castList === void 0 ? void 0 : castList[0]) === null || _a === void 0 ? void 0 : _a.description) ? setShowAllItems(true) : {}; }}/>
            </div>}
        {/* {showAllItems && <BottomSheet handleDismiss={() => setShowAllItems(false)} title='Artist'>
            <div css={css([castCon, castConBottom])}>
                {castList.map((cast:Cast, idx: any) => (
                    <LineupItem
                        key={`bottom.cast.name_${idx}`}
                        img={cast.imageUrl}
                        title={cast.name}
                        extraText={cast?.extraText ?? ''}
                        subTitle={cast.description}
                    />
                ))}
            </div>
                </BottomSheet>} */}
    </React.Fragment>);
};
const castCon = `
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 34px;
  overflow-y: auto;
//   flex-wrap: wrap;
`;
// const castConBottom = `
//     display: flex;
//     flex-direction: column;
//     gap: 4px;
//     & > div {
//         border-bottom: 1px solid ${colors['--Colors-Neutral-Grey-700']};
//         padding: 16px 0;
//         &:last-child {
//             border-bottom: none;
//         }
//     }
// `;
export default ArtistList;
const titleCont = `
    ${flexBox('space-between', 'center')}
    gap: 16px;
    h6 {
        margin-bottom: 20px;
    }
`;
