import * as React from "react";
import { getJSBridge } from "../helpers/appBridge";
const openLink = (href, target) => {
    if (href) {
        const JSBridge = getJSBridge();
        if (JSBridge) {
            JSBridge.call("openInBrowser", {
                url: href
            });
        }
        else {
            window.open(href, target);
        }
    }
};
export const LinkWithH5Support = ({ href, onClick, children, target = "_blank", className }) => (<a className={className} href="#" onClick={() => {
        openLink(href, target);
        onClick && onClick();
    }}>
		{children}
	</a>);
