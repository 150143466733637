import React from "react";
export const XDarkIcon = (props) => {
    const { fillColor = '#FCFCFD' } = props;
    return (<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_6462_798)">
				<path d="M17.3381 12.8116L27.7144 0.75H25.2556L16.2458 11.2229L9.04979 0.75H0.75L11.6318 16.5869L0.75 29.2354H3.20899L12.7235 18.1756L20.3231 29.2354H28.6229L17.3375 12.8116H17.3381ZM13.9702 16.7264L12.8677 15.1494L4.095 2.60109H7.87186L14.9515 12.728L16.0541 14.305L25.2567 27.4685H21.4799L13.9702 16.727V16.7264Z" fill={fillColor}/>
			</g>
			<defs>
				<clipPath id="clip0_6462_798">
					<rect width="27.8729" height="28.5" fill="white" transform="translate(0.75 0.75)"/>
				</clipPath>
			</defs>
		</svg>);
};
