/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Map } from "immutable";
import React, { useMemo } from "react";
// import ContentComponents from "../ContentComponents";
import RichTextTitle from "../RichTextTitle";
import VenueCard from "../VenueCard";
import PromoBanner from "../PromoBanner";
import InsiderPassLink from "../InsiderPassLink";
// import SeatMapImage from "../SeatMapImage";
// import EventSafetyMeasures from "./EventSafetyMeasures";
import EDPShare from "../EDPShare";
import ImageList from "../ImageList";
import RichTextDisplay from "../RichTextDisplay";
import VideoCard from "../VideoCard";
import SalientFeatures from "../SalientFeatures";
// components
// import { Accordion } from "../Accordion";
// import { AccordionPanel } from "../AccordionPanel";
// import { AccordionTitle } from "../AccordionTitle";
import theme, { colors, mediaFeatures } from "../../styles/common";
import InsiderLoadable from "../InsiderLoadable";
// import InsiderLinkContext from "../../contexts/InsiderLinkContext";
// import ProhibitedList from "./ProhibitedList";
import { generatePartnerProfileLink, imageCacheLink } from "../../helpers/linkGenerators";
import ProhabitedListV2 from "./ProhibitedList/indexV2";
import LineupList from "../ui-lib/newCompo/LineupList";
import ArtistList from "../ui-lib/newCompo/ArtistList";
import HorizontalGrid from "../ui-lib/newCompo/HorizontalGrid";
import { BackgroundColor, titleDefaultStyle } from "../ui-lib/Atoms/Typography/stylesUtils";
import Accordion from "../ui-lib/Atoms/Accordion";
import EventAboutReadMore from "./EventAboutReadMore";
import { getSanitisedHtml } from "../../helpers/misc";
const EDPReportSection = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "EDPReportSection" */ "../EDPReportSection")
});
const mobileDisplay = `
	display:none;
	${theme.mediaQueries["for-mobile-and-tablet"]} {
		display: block;
	};
	position: sticky;
    bottom: 65px;
`;
const EventSummaryCardDetailWrap = `
	display: none;
	${theme.mediaQueries["for-mobile"]} {
		display: block;
	}
	${theme.mediaQueries["for-small-tablet-only"]} {
		display: block;
	}
`;
// have to do this only once
const metadataForEventText = new Map({
    metadata: new Map({
        align: "left"
    })
});
const EventDescriptionStyle = `
	& span {
		font-size: 19px;
		margin-bottom: 30px;
		margin-top: 30px;
		font-weight: bold;
		color: ${colors.descriptionColor};
		display: block;
		@media screen and ${mediaFeatures["for-large-tablet"]} {
			font-size: 21px;
		}
	}
`;
const hideOnDesktop = `
	@media screen and ${mediaFeatures["for-pc"]} {
		display: none;
	}
`;
const blockIOSTitle = `
	font-size:16px;
	font-weight: bold;
`;
const blockIOSDesc = `
	font-size:16px;
	margin-top:8px;
`;
const blockIOSDiv = `    
	padding: 18px 9px;
	border-radius: 8px;
	width: 88vw;
	background: lightyellow;`;
const sectionMargin = `
	margin: 45px auto;
    h6 {
        margin-bottom: 20px;
    }
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		margin: 30px auto;
	}
`;
const accordionContStyle = `
    ${BackgroundColor('--Background-BG-White')}
    border-radius: 9px;
    border: 0.5px solid #F0F1F2;
	padding: 6px;
	margin: 20px 0;
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		// margin: 30px auto;
	}
`;
const EventTerms = ({ terms }) => {
    if (!terms) {
        return null;
    }
    let termsMarkup = terms.reduce((a, t) => a + `<li>${t}</li>`, "");
    termsMarkup = `<ul>${termsMarkup}</ul>`;
    const metadataForTermsPanel = new Map({
        metadata: new Map({
            align: "left"
        })
    });
    return (<Accordion title='Terms & Conditions' contStyles={accordionContStyle}>
			<RichTextDisplay cssStyle={'font-size: 14px; padding-right: 16px;'} metaClass="custom-widget-content" data={metadataForTermsPanel.set("content", termsMarkup)}/>
		</Accordion>
    // <Accordion key="event-accordion-terms" className="text">
    // 	<AccordionTitle>
    // 		<span
    // 			css={{
    // 				fontSize: 19,
    // 				fontWeight: "bold",
    // 				color: colors.descriptionColor,
    // 				[theme.mediaQueries["for-large-tablet"]]: {
    // 					fontSize: 25
    // 				}
    // 			}}
    // 		>
    // 			<span className="icon-fa icon-plus" />
    // 			Terms & Conditions
    // 		</span>
    // 	</AccordionTitle>
    // 	<AccordionPanel>
    // 		<RichTextDisplay metaClass="custom-widget-content" data={metadataForTermsPanel.set("content", termsMarkup)} />
    // 	</AccordionPanel>
    // </Accordion>
    );
};
const Qna = ({ qna }) => {
    if (!qna || qna.length === 0) {
        return null;
    }
    return (<Accordion title='Frequently Asked Questions' contStyles={accordionContStyle}>
			<div>
				{qna.map((item, index) => {
            var _a, _b;
            const que = (_a = item === null || item === void 0 ? void 0 : item.heading) !== null && _a !== void 0 ? _a : '';
            const ans = (_b = item === null || item === void 0 ? void 0 : item.subheading) !== null && _b !== void 0 ? _b : '';
            return (<div key={`qnz_${index}`} css={css([qnaItemCont])}>
							<p css={css([qnaItemQue])}>{que}</p>
							<p css={css([qnaItemAns])}>{ans}</p>
						</div>);
        })}
			</div>
		</Accordion>);
};
/**
 * Check if Youtube embed link
 * @param {String} embedHtml
 * @returns {boolean}
 */
const isYoutubeEmbed = (embedHtml) => {
    return /src=".+youtube/.test(embedHtml);
};
/**
 * Extract Youtube Video src (https://regex101.com/r/zYtlTe/1)
 * @param {String} embedHtml
 * @returns {null | String}
 */
const getVideoURL = (embedHtml) => {
    const match = /src="([^"]+)"/g.exec(embedHtml);
    return match != null ? match[1] : null;
};
const EventCustomHtml = (props) => {
    if (isYoutubeEmbed(props.sectionData.get("html")) && getVideoURL(props.sectionData.get("html")) !== null) {
        return (<VideoCard data={new Map({
                content: new Map({
                    embedsrc: getVideoURL(props.sectionData.get("html")),
                    caption: ""
                })
            })}/>);
    }
    return (<div css={customHtmlCont} dangerouslySetInnerHTML={{ __html: getSanitisedHtml(props.sectionData.get("html")) }}/>
    // <RichTextDisplay
    // 	cssStyle={`margin-left: 0 !important;`}
    // 	data={metadataForEventText.set("content", `${props.sectionData.get("html")}`)}
    // />
    );
};
const EventLineUp = props => {
    if (props.lineupData.size > 0) {
        const lineupCarouselItems = new Map({
            content: new Map({
                images: props.lineupData.map(v => {
                    return new Map({
                        src: v.get("imgUrl"),
                        caption: v.get("name"),
                        height: 240,
                        width: 360,
                        href: v.get("href"),
                    });
                }),
                imageListTitle: ""
            }),
            metadata: new Map({
                display: "carousel-limited",
                numCarouselItems: 2
            })
        });
        return <ImageList data={lineupCarouselItems} carouselCSS={`margin-left: 0px !important;`}/>;
    }
    return null;
};
/**
 * Cleans up the event description
 * Converts \n to <p> tags
 * Adds the About header
 * @param {string} description
 * @return {React.Element}
 */
const EventDescription = ({ description, limit }) => {
    const sanitizedDescription = description
        .split("\n")
        .filter(v => v) // remove empty strings
        .map(v => `<p>${v}</p>`)
        .join("<br/>");
    return (<div css={css(['margin: 15px 0;'])}>
			<p css={css([titleDefaultStyle, 'margin-bottom: 15px;'])}>About the Event</p>
			<EventAboutReadMore content={sanitizedDescription} limit={limit}/>
		</div>);
};
const EventGuide = props => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { eventData, isSafetyMeasuresPresent, shouldSkipShareSection, isFromApp, promoData, summaryCardDetail, shouldBlockiOSPurchase, type, reportEventHandler, queryParams, searchParams, shouldSkipReportSection, shouldShowPrerequisitiesSection, salientFeature } = props;
    const eventDataInJs = useMemo(() => {
        var _a;
        return (_a = eventData === null || eventData === void 0 ? void 0 : eventData.toJS()) !== null && _a !== void 0 ? _a : {};
    }, [eventData]);
    // const eventCategory = eventDataInJs?.category_id?.name ?? "";
    const eventDescription = (_a = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.description) !== null && _a !== void 0 ? _a : "";
    const artistLineup = (_b = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.lineup) !== null && _b !== void 0 ? _b : [];
    const galleryImage = (_c = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.images) !== null && _c !== void 0 ? _c : [];
    const venueDetails = (_d = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.venue) !== null && _d !== void 0 ? _d : {};
    const terms = (_e = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.terms) !== null && _e !== void 0 ? _e : [];
    const customHtml = (_f = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.custom_html) !== null && _f !== void 0 ? _f : [];
    const components = (_g = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.components) !== null && _g !== void 0 ? _g : [];
    const tagsArray = (_h = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.tags) !== null && _h !== void 0 ? _h : [];
    const isOnline = (eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.event_type) === 'online';
    const getArtistViaTags = useMemo(() => {
        let artistArray = [];
        // to-do add isActice check
        const artistFromBE = tagsArray.filter((tag) => { var _a; return ((_a = tag === null || tag === void 0 ? void 0 : tag.tag_id) === null || _a === void 0 ? void 0 : _a.type) === "artist"; });
        if (artistFromBE && artistFromBE.length > 0) {
            artistArray = artistFromBE.map((item) => {
                var _a, _b;
                const obj = (_a = item === null || item === void 0 ? void 0 : item.tag_id) !== null && _a !== void 0 ? _a : {};
                return Object.assign(Object.assign(Object.assign({}, item), obj), { imageUrl: imageCacheLink((_b = obj === null || obj === void 0 ? void 0 : obj.share_image) !== null && _b !== void 0 ? _b : ""), href: generatePartnerProfileLink(obj === null || obj === void 0 ? void 0 : obj.slug, obj === null || obj === void 0 ? void 0 : obj.type) });
            });
        }
        return artistArray;
    }, [tagsArray]);
    const iconTextList = useMemo(() => {
        const iconTextListFromBE = components.filter((component) => component.type === "icon_text_list" && !component.is_hidden);
        let array = [];
        if (iconTextListFromBE.length > 0) {
            iconTextListFromBE.forEach((item) => {
                var _a;
                const iconTextList = (_a = item === null || item === void 0 ? void 0 : item.content) !== null && _a !== void 0 ? _a : {};
                array.push(iconTextList);
            });
        }
        return array;
    }, [components]);
    const getLineup = useMemo(() => {
        const lineupFromBE = artistLineup !== null && artistLineup !== void 0 ? artistLineup : [];
        const updatedArtistLineup = lineupFromBE.map((item) => {
            var _a, _b;
            const genre = (_b = (_a = item === null || item === void 0 ? void 0 : item.genres) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : "";
            return Object.assign(Object.assign({}, item), { extraText: genre });
        });
        return updatedArtistLineup;
    }, [artistLineup]);
    const getFaqs = useMemo(() => {
        var _a, _b;
        const faqFromBE = components.find((component) => component.type === "qna" && !component.is_hidden);
        let array = [];
        if (faqFromBE) {
            const faqArray = (_b = (_a = faqFromBE === null || faqFromBE === void 0 ? void 0 : faqFromBE.content) === null || _a === void 0 ? void 0 : _a.heading_subheading) !== null && _b !== void 0 ? _b : [];
            array = [
                ...array,
                ...faqArray
            ];
        }
        return array;
    }, [components]);
    const getEDPTabs = useMemo(() => {
        const tabs = [];
        if (eventDescription)
            tabs.push({ id: "about", title: "About" });
        if (getArtistViaTags.length > 0)
            tabs.push({ id: "artist", title: "Artist" });
        if (getLineup.length > 0)
            tabs.push({ id: "lineup", title: "Lineup" });
        if (galleryImage.length > 0)
            tabs.push({ id: "gallery", title: "Gallery" });
        if (iconTextList.length > 0) {
            iconTextList.forEach((item) => {
                var _a, _b;
                const header = (_a = item === null || item === void 0 ? void 0 : item.header) !== null && _a !== void 0 ? _a : "";
                const iconText = (_b = item === null || item === void 0 ? void 0 : item.icon_text) !== null && _b !== void 0 ? _b : [];
                if (header && iconText.length > 0) {
                    tabs.push({
                        id: header.toLowerCase(),
                        title: header
                    });
                }
            });
        }
        if (venueDetails && !isOnline)
            tabs.push({ id: "venue", title: "Venue" });
        return tabs;
    }, [eventDescription, getArtistViaTags, getLineup, galleryImage, iconTextList, venueDetails, isOnline]);
    return (<div>
			<div css={css(EventSummaryCardDetailWrap)}>
				{summaryCardDetail}
				{props.seperator}
			</div>

			{shouldBlockiOSPurchase && (<div css={[css(blockIOSDiv), css(hideOnDesktop)]}>
					<p css={css(blockIOSTitle)}>You cannot purchase this on the app.</p>
					<p css={css(blockIOSDesc)}>
						Sorry, we know it sucks but head to your browser and get them. If you've already purchased it elsewhere,
						head over to the ticket section to view the details.
					</p>
				</div>)}

			<div data-section id="about">
				<EventDescription description={eventDescription} limit={getEDPTabs.length > 2 ? 220 : 400}/>
				{salientFeature && salientFeature.size > 0 && (<React.Fragment>
					{props.separator}
					<SalientFeatures salient_features={salientFeature} venue={eventData.getIn(["venue", "name"]) || eventData.get("venue_name")} type={type}/>
				</React.Fragment>)}
			</div>

			<div css={{
            marginTop: 30,
            [theme.mediaQueries["for-pc"]]: {
                display: "none"
            }
        }}>
				{!shouldSkipShareSection && <EDPShare showShareButtons={props.showShareButtons} seperator={props.seperator}/>}
			</div>

			{promoData && <PromoBanner toLink={promoData.articleLink} imageSrc={promoData.imageLink}/>}

			{getArtistViaTags.length > 0 && <div data-section id="artist" css={css([sectionMargin])}>
				<ArtistList castList={getArtistViaTags} title="Artist"/>
			</div>}

			{getLineup.length > 0 && <div data-section id="lineup" css={css([sectionMargin])}>
				<LineupList castList={getLineup} title="Lineup"/> 
			</div>}

			{galleryImage.length > 0 && <div data-section id="gallery" css={css([sectionMargin])}> 
				<h6 css={css([titleDefaultStyle])}>Gallery</h6>
				<HorizontalGrid data={galleryImage} keyName={"medium"} onClick={data => console.log(data, "clicked")}/>
			</div>}

			{iconTextList.length > 0 && iconTextList.map((item, index) => {
            var _a, _b, _c, _d;
            const header = (_a = item === null || item === void 0 ? void 0 : item.header) !== null && _a !== void 0 ? _a : '';
            const subHeader = (_b = item === null || item === void 0 ? void 0 : item.subheader) !== null && _b !== void 0 ? _b : '';
            const data = (_d = (_c = item === null || item === void 0 ? void 0 : item.icon_text_list) !== null && _c !== void 0 ? _c : item === null || item === void 0 ? void 0 : item.icon_text) !== null && _d !== void 0 ? _d : [];
            const sectionId = header === null || header === void 0 ? void 0 : header.toLowerCase();
            return (<div key={`icon_text_section.${index}`} data-section id={`${sectionId}`} css={css([sectionMargin])}>
						<ProhabitedListV2 key={index} title={header} subTitle={subHeader} list={data}/>
					</div>);
        })}

			{/* <div>
            {customHtml.length > 0 &&
                customHtml.map((item: any, index: number) => {
                    const title = item?.title ?? "";
                    const html = item?.html ?? "";
                    console.log(html ,title, "title")
                    
                    return (
                        <div css={css([mainIndiCont])} key={`custom_compo_${index}_${title}`}>
                            <h6 css={css([titleDefaultStyle])}>{title}</h6>
                            <div css={customHtmlCont}>
                                <div  key={`custom_html-${index}`} css={customHtmlCont} dangerouslySetInnerHTML={{ __html: getSanitisedHtml(html) }} />
                            </div>
                        </div>
                    );
                })
            }
        </div> */}

				{/* <section
            className="text"
            css={css(
                `
                    margin: 30px auto;
                    @media screen and ${mediaFeatures["for-pc"]} {
                        display: none
                    }
                `
            )}
        >
            <SalientFeatures
                salient_features={salientFeature}
                venue={eventData.getIn(["venue", "name"]) || eventData.get("venue_name")}
                type={type}
            />
            {props.seperator}
        </section>
    )} */}
			
			{/* mobile share */}
			{/* <div
            css={{
                marginTop: 30,
                [theme.mediaQueries["for-pc"]]: {
                    display: "none"
                }
            }}
        >
            <div
                css={{
                    marginTop: 30,
                    [theme.mediaQueries["for-tablet"]]: {
                        display: "none"
                    }
                }}
            >
                {isSafetyMeasuresPresent && <EventSafetyMeasures safety_measures={eventData.get("safety_measures")} />}
            </div>
            {!shouldSkipShareSection && <EDPShare showShareButtons={props.showShareButtons} seperator={props.seperator} />}
        </div> */}

			{/* {isSafetyMeasuresPresent && (
            <section
                className={"text text-left"}
                css={css([
                    {
                        [theme.mediaQueries["for-mobile"]]: {
                            display: "none"
                        }
                    }
                ])}
            >
                <EventSafetyMeasures safety_measures={eventData.get("safety_measures")} />
            </section>
        )} */}

			{/* {props.seperator}
        {eventData.has("_id") && eventData.get("_id") === "5d5be5d976964c0008123c55" && (
            <SeatMapImage
                imageURL="https://media.insider.in/image/upload/c_crop,g_custom/v1570705545/g4rjzh81yvw6enxpoyva.png"
                redirectURL="/event/oneplus-music-festival-nov16-2019/buy"
            />
        )} */}
			{/* {promoData && <PromoBanner toLink={promoData.articleLink} imageSrc={promoData.imageLink} />} */}
			{/* {promoData && props.seperator} */}
			{/* {getArtistViaTags.length > 0 && <div css={css([sectionMargin])}>
            <ArtistList castList={getArtistViaTags} title="Artist" />
        </div>} */}
			{/* {getArtistViaTags.length > 0 && <React.Fragment>
            <RichTextTitle text="Artist" />
            <EventLineUp lineupData={fromJS(getArtistViaTags)} />
        </React.Fragment>} */}
			{/* {getLineup.length > 0 && <div css={css([sectionMargin])}>
            <LineupList castList={getLineup} title="Lineup" />
        </div>} */}
			{/* {eventData.has("lineup") && eventData.get("lineup").size > 0 && <RichTextTitle text="Line-up" />}
        <EventLineUp lineupData={eventData.get("lineup")} /> */}
			{/* {getLineup.has("lineup") && eventData.get("lineup").size > 0 && props.seperator} */}
			{/* {getGallery.length > 0 && <div css={css([sectionMargin])}>
            <h6 css={css([titleDefaultStyle])}>Gallery</h6>
            <HorizontalGrid data={getGallery} keyName={"medium"} onClick={data => console.log(data, "clicked")} />
        </div>} */}
			{/* {eventData.has("images") && eventData.get("images").size > 0 && <RichTextTitle text="Gallery" />}
        <EventImages images={eventData.get("images")} /> */}
			{/* {eventData.has("images") && eventData.get("images").size > 0 && props.seperator} */}
			{/* {iconTextList.length > 0 && iconTextList.map((item: any, index: number) => {
            const header = item?.header ?? '';
            const subHeader = item?.subheader ?? '';
            const data = item?.icon_text_list ?? item?.icon_text ?? [];
            return (
                <ProhabitedListV2 key={index} title={header} subTitle={subHeader} list={data} />
            );
        })} */}
			{eventData
            .get("custom_html")
            .map((v, i) => [
            <RichTextTitle key={`rich-text-title-${i}`} text={v.get("title")}/>,
            <EventCustomHtml key={`custom-html-${i}`} sectionData={v}/>,
            props.seperator
        ])
            .flatten()}
			{/* <InsiderLinkContext.Provider value={{ searchParams: searchParams }}>
            <ContentComponents cssStyle={`margin-left: 0 !important;`} components={props.contentComponentsData} />
        </InsiderLinkContext.Provider> */}
			{/* {props.contentComponentsData.size > 0 && props.seperator} */}
			{/* <ProhibitedList event_id={eventData.get('_id')} /> */}
			<VenueCard name={eventData.getIn(["venue", "name"])} latitude={eventData.getIn(["venue", "id", "geolocation", "latitude"])} longitude={eventData.getIn(["venue", "id", "geolocation", "longitude"])} address={eventData.getIn(["venue", "id", "address"])} isFromApp={isFromApp}/>

			<div css={css(['margin: 30px 0;'])}>
				<Qna qna={getFaqs}/>
			
				<EventTerms terms={eventData.get("terms")}/>
			</div>
			
			{shouldShowPrerequisitiesSection && props.prerequisites && props.prerequisites.length > 0 && (<div css={css(mobileDisplay)}>
					<InsiderPassLink prerequisites={props.prerequisites} isFromApp={isFromApp} event_id={eventData.get('_id')}/>
				</div>)}
			{!shouldSkipReportSection && (<div css={css(hideOnDesktop)}>
					<EDPReportSection reportEventHandler={reportEventHandler} isFromApp={isFromApp} queryParams={queryParams}/>
				</div>)}
		</div>);
};
export default EventGuide;
const stickyStyle = `
    position: sticky;
    top: 60px;
    backdrop-filter: blur(5px);
	background: #FAFAFA;
`;
const mainIndiCont = `
    margin: 45px 0;
    h6 {
        margin-bottom: 20px;
		${titleDefaultStyle}
    }
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		margin: 30px auto;
	}
`;
const customHtmlCont = css `
	word-break: normal;
	overflow-wrap: break-word;
	white-space: normal;
    iframe, img, svg {
        width: -webkit-fill-available;
		border-radius: 12px;
    }
	ul {
		list-style: none;
		padding-left: 0;
	}
`;
const qnaItemCont = `
	padding: 12px;
	border-bottom: 0.75px solid ${colors.dividerGrey};
	p {
		color: ${colors['hover-primary-blue']}
	}
	&:last-child {
		border-bottom: 0px;
	}
`;
const qnaItemQue = `
	font-size: 14px;
	font-weight: 600;
`;
const qnaItemAns = `
	font-size: 12px;
	font-weight: 400;
	margin-top: 4px;
`;
