import { mediaFeatures } from "../common";
import { columns, gutter } from "./functions";
import { mobileColumns, mobileSideGutter, pcColumns, tabletColumns } from "./variables";
export const span = (columnsOnMobile, columnsOnTablet, columnsOnPc, columnsOnHd, type = "single", height = false, widthType = "normal", float = "left", wrapperColumns = pcColumns) => `
    ${float !== "none" ? `float: ${float};` : ""};
    width: 0;
    @media screen and ${mediaFeatures["for-mobile"]} {
        width: ${columns("mobile", columnsOnMobile)}vw;
        ${height ? `height: ${columns("mobile", columnsOnMobile) * Number(height)}vw;` : ""}
        ${type === "list" || type === "grid" ? `margin-right: ${gutter("mobile")}vw;` : ""}
        ${type === "grid"
    ? `
            &:not(.short-list-item):not(.footer-list-item) {
                margin-bottom: ${mobileSideGutter}vw;
            }
            &:nth-child(${mobileColumns / columnsOnMobile}n) {
                margin-right: 0;
            }
            &:nth-child(${mobileColumns / columnsOnMobile}n+1):nth-last-child(-n+${mobileColumns / columnsOnMobile}),
            &:nth-child(${mobileColumns / columnsOnMobile}n+1):nth-last-child(-n+${mobileColumns /
        columnsOnMobile}) ~ * {
                margin-bottom: 0;
            }
        `
    : ""}
    }
    @media screen and ${mediaFeatures["for-tablet-only"]} {
        width: ${columns("tablet", columnsOnTablet)}vw;
        ${height ? `height: ${columns("tablet", columnsOnTablet) * Number(height)}vw;` : ""}
        ${type === "list" || type === "grid" ? `margin-right: ${gutter("tablet")}vw;` : ""}
        ${type === "grid"
    ? `
            &:not(.footer-list-item) {
                margin-bottom: ${gutter("tablet")}vw;
            }
            &:nth-child(${tabletColumns / columnsOnTablet}n) {
                margin-right: 0;
            }
            &:nth-child(${tabletColumns / columnsOnTablet}n+1):nth-last-child(-n+${tabletColumns / columnsOnTablet}),
            &:nth-child(${tabletColumns / columnsOnTablet}n+1):nth-last-child(-n+${tabletColumns /
        columnsOnTablet}) ~ * {
                margin-bottom: 0;
            }
        `
    : ""}
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        width: ${columns("pc", columnsOnPc, widthType)}vw;
        ${height ? `height: ${columns("pc", columnsOnPc, widthType) * Number(height)}vw;` : ""}
        ${type === "list" || type === "grid" ? `margin-right: ${gutter("pc")}vw;` : ""}
        ${type === "grid"
    ? `
            &:not(.footer-list-item) {
                margin-bottom: ${gutter("pc")}vw;
            }
            &:nth-child(${wrapperColumns / columnsOnPc}n) {
                margin-right: 0;
            }
            &:nth-child(${wrapperColumns / columnsOnPc}n+1):nth-last-child(-n+${wrapperColumns / columnsOnPc}),
            &:nth-child(${wrapperColumns / columnsOnPc}n+1):nth-last-child(-n+${wrapperColumns / columnsOnPc}) ~ * {
                margin-bottom: 0;
            }
        `
    : ""}
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        width: ${columns("hd", columnsOnHd, widthType)}px;
        ${height ? `height: ${columns("hd", columnsOnHd, widthType) * Number(height)}px;` : ""}
        ${type === "list" || type === "grid" ? `margin-right: ${gutter("hd")}px;` : ""}
        ${type === "grid"
    ? `
            &:not(.footer-list-item) {
                margin-bottom: ${gutter("hd")}px;
            }
            &:nth-child(${wrapperColumns / columnsOnHd}n) {
                margin-right: 0;
            }
            &:nth-child(${wrapperColumns / columnsOnHd}n+1):nth-last-child(-n+${wrapperColumns / columnsOnHd}),
            &:nth-child(${wrapperColumns / columnsOnHd}n+1):nth-last-child(-n+${wrapperColumns / columnsOnHd}) ~ * {
                margin-bottom: 0;
            }
        `
    : ""}
    }
`;
