import React from "react";
import Advertisement from "../../components/Advertisement";
import { sendClevertapEvent } from "../../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../../helpers/constants";
import { checkPhoenixViaUserAgent, openInBrowserForApp } from "../../helpers/appBridge";
import { pushWindow } from "../../helpers/h5";
import { DATA_REFS } from "../../helpers/constants/dataRefs";
;
export default class EventAdBanner extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            server: true,
            desktopTrackingImage: "",
            mobileTrackingImage: ""
        };
        this.sendEventToClevertap = () => {
            const { bannerName, eventId, eventName } = this.props;
            sendClevertapEvent(CLEVERTAP_ACTIONS.AD_CLICK, {
                bannerName: bannerName,
                eventId: eventId,
                eventName: eventName
            });
        };
        this.shouldOpenBannerLinkOnApp = (bannerUrl) => {
            if (bannerUrl.includes('/event') || bannerUrl.includes('/article') || bannerUrl.includes('/go')) {
                return true;
            }
            return false;
        };
        this.adOnClick = (event) => {
            const { mobileHref, queryParams: { platform, version } } = this.props;
            this.sendEventToClevertap();
            if (this.props.isFromApp && !this.props.shouldShowVideoModal) {
                event.preventDefault();
                if (this.shouldOpenBannerLinkOnApp(mobileHref)) {
                    const isFirstParam = !mobileHref.includes("?");
                    const urlQueryParams = `platform=${platform}${version ? `&version=${version}` : ``}`;
                    const bannerLinkWithParams = `${mobileHref}${isFirstParam ? "?" : "&"}${urlQueryParams}`;
                    pushWindow(bannerLinkWithParams);
                }
                else {
                    if (checkPhoenixViaUserAgent()) {
                        openInBrowserForApp(mobileHref);
                    }
                    if (window.Insider && Insider.goTo) {
                        Insider.goTo(mobileHref);
                    }
                }
            }
            if (this.props.shouldShowVideoModal) {
                event.preventDefault();
                this.props.setModalType({
                    modalType: "VideoModal",
                    modalKey: this.props.eventId,
                    videoUrl: this.props.videoUrl
                });
            }
        };
    }
    shouldComponentUpdate(_nextProps, nextState) {
        return this.state.server !== nextState.server;
    }
    componentDidMount() {
        let { desktopTrackingImage, mobileTrackingImage } = this.props;
        // if tracking images exist add the timestamp
        desktopTrackingImage && (desktopTrackingImage = `${desktopTrackingImage}${Date.now()}`);
        mobileTrackingImage && (mobileTrackingImage = `${mobileTrackingImage}${Date.now()}`);
        this.setState(() => {
            return {
                server: false,
                desktopTrackingImage: desktopTrackingImage,
                mobileTrackingImage: mobileTrackingImage
            };
        });
    }
    render() {
        const { desktopImageUrl, mobileImageUrl, lazy, shouldShowVideoModal, desktopHref, mobileHref, cssStyle } = this.props;
        const { desktopTrackingImage, mobileTrackingImage } = this.state;
        let desktopUrl = shouldShowVideoModal ? null : desktopHref;
        let mobileUrl = shouldShowVideoModal ? null : mobileHref;
        return (<div>
				<Advertisement target="_blank" bannerImage={mobileImageUrl} className="advertisement advertisement-mobile" href={mobileUrl} imgWidth={800} imgWidths={[560, 750, 600, 800]} lazy={lazy} trackingImage={mobileTrackingImage} onClick={this.adOnClick} autoPlay={true} showAdLabel={false} dataRef={`${DATA_REFS.EDP_EVENT_AD_BANNER_IMAGE}_mobile`}/>
				<Advertisement target="_blank" bannerImage={desktopImageUrl} className="advertisement advertisement-desktop" href={desktopUrl} imgWidth={1024} imgWidths={[560, 750, 600, 800, 1024]} lazy={lazy} trackingImage={desktopTrackingImage} onClick={this.adOnClick} autoPlay={true} showAdLabel={false} style={cssStyle} dataRef={`${DATA_REFS.EDP_EVENT_AD_BANNER_IMAGE}_desktop`}/>
			</div>);
    }
}
