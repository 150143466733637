/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component } from "react";
import classNames from "classnames";
import TwitterShareButton from "../TwitterShareButton";
import { ShareCrossIcon } from "../ShareCrossIcon";
import { dimensions, flexBox } from "../ui-lib/Atoms/Typography/stylesUtils";
class ShareButtonsRow extends Component {
    constructor(props) {
        super(props);
        this.showCloseModalButton = () => {
            if (!this.props.hideCloseButton) {
                return (<button id="closeModalButton" onClick={this.props.handleDismiss} className="from-pill icon-round icon-close " role="close">
					<ShareCrossIcon />
				</button>);
            }
        };
        this.state = {
            shouldClose: false
        };
    }
    render() {
        const { shouldClose } = this.state;
        const { text, url, position, modifierClass, sendEdpShareFallbackEvent, name, isFromApp } = this.props;
        if (!text || !url || shouldClose) {
            return null;
        }
        return (<div className={classNames(`social-sharing`, modifierClass)} css={css([flexCont])}>
				<TwitterShareButton text={text} url={url} position={position} sendEdpShareFallbackEvent={sendEdpShareFallbackEvent} name={name} isFromApp={isFromApp}/>
				{/* <WhatsappShareButton
                text={text}
                url={url}
                position={position}
                sendEdpShareFallbackEvent={sendEdpShareFallbackEvent}
                name={name}
                isFromApp={isFromApp}
            /> */}
				{this.showCloseModalButton()}
			</div>);
    }
}
ShareButtonsRow.defaultProps = {
    position: "default",
    sendEdpShareFallbackEvent: () => { },
    hideCloseButton: false
};
export default ShareButtonsRow;
const flexCont = `
	${flexBox('flex-start')}
	gap: 8px;
	img, svg {
		${dimensions('16px', '16px')}
	}
`;
