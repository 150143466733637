import React from "react";
export class ShareIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
				<path d="m14.786 0a3.224 3.224 0 0 0 -3.214 3.214c0 .288.041.566.113.832l-5.947 2.974a3.21 3.21 0 0 0 -2.524-1.234 3.224 3.224 0 0 0 -3.214 3.214 3.224 3.224 0 0 0 3.214 3.214c1.021 0 1.933-.485 2.523-1.233l5.948 2.973a3.196 3.196 0 0 0 -.113.832 3.224 3.224 0 0 0 3.214 3.214 3.224 3.224 0 0 0 3.214-3.214 3.224 3.224 0 0 0 -3.214-3.214 3.21 3.21 0 0 0 -2.524 1.234l-5.947-2.974c.072-.266.113-.544.113-.832s-.041-.565-.113-.831l5.948-2.974a3.211 3.211 0 0 0 2.523 1.233 3.224 3.224 0 0 0 3.214-3.214 3.224 3.224 0 0 0 -3.214-3.214zm0 1.286a1.92 1.92 0 0 1 1.929 1.929 1.92 1.92 0 0 1 -1.93 1.929 1.92 1.92 0 0 1 -1.928-1.93 1.92 1.92 0 0 1 1.929-1.928zm-11.572 5.784a1.92 1.92 0 0 1 1.929 1.93 1.92 1.92 0 0 1 -1.929 1.929 1.92 1.92 0 0 1 -1.929-1.929 1.92 1.92 0 0 1 1.93-1.929zm11.572 5.786a1.92 1.92 0 0 1 1.929 1.929 1.92 1.92 0 0 1 -1.93 1.929 1.92 1.92 0 0 1 -1.928-1.93 1.92 1.92 0 0 1 1.929-1.928z"/>
			</svg>);
    }
}
