import React from "react";
import classNames from "classnames";
import { getCategoryNameFromResource, getComputedCategoryColour } from "../selectors/index.js";
import RegularSummaryCard from "./RegularSummaryCard";
import LargeSummaryCard from "./LargeSummaryCard";
import SummaryCardHeader from "./SummaryCardHeader";
import SummaryCardShare from "./SummaryCardShare";
import { NO_DATA_CLASS } from "../helpers/constants";
import VideoSummaryCard from "../containers/VideoSummaryCardWithSubscription";
class SummaryCard extends React.PureComponent {
    showShareButtons(headerShareClass, isEDP) {
        if (!this.props.shareUrl) {
            return null;
        }
        const pageType = this.props.actionButton ? "actionable" : "";
        return <SummaryCardShare shareText={this.props.shareText} shareUrl={this.props.shareUrl} isEDP={isEDP}/>;
    }
    getClassNames() {
        const shellClass = this.props.title ? "" : NO_DATA_CLASS;
        const appClass = this.props.isSPA ? "" : "app";
        return {
            header: classNames("header", "header-" + this.props.headerType, shellClass, appClass),
            headerVisual: classNames(shellClass),
            headerDetail: classNames(shellClass),
            headerTitle: classNames("header-title", shellClass),
            headerSummary: classNames(this.props.summaryType, shellClass),
            headerSocial: classNames("header-social", appClass),
            headerDesc: classNames("header-desc")
        };
    }
    showHeaderImageOrVideo() {
        return (<SummaryCardHeader headerImage={this.props.headerImage} headerType={this.props.headerType} headerVideo={this.props.headerVideo} title={this.props.title}/>);
    }
    render() {
        const categoryName = this.props.cardData ? getCategoryNameFromResource(this.props.cardData) : undefined;
        const categoryColour = this.props.cardData ? getComputedCategoryColour(this.props.cardData) : undefined;
        const { headerSocial } = this.getClassNames();
        if (this.props.headerType === "large") {
            return (<LargeSummaryCard {...this.getClassNames()} slug={this.props.slug} categoryName={categoryName} categoryColour={categoryColour} title={this.props.title} summary={this.props.summary} headerImageOrVideo={this.showHeaderImageOrVideo()} shareButtons={this.showShareButtons(headerSocial)} actionButton={this.props.actionButton}/>);
        }
        else if (this.props.headerType === "youtube") {
            return (<VideoSummaryCard {...this.getClassNames()} categoryName={categoryName} categoryColour={categoryColour} title={this.props.title} summary={this.props.summary} headerImageOrVideo={this.showHeaderImageOrVideo()} shareButtons={this.showShareButtons(headerSocial, true)} actionButton={this.props.actionButton}/>);
        }
        else {
            return (<RegularSummaryCard {...this.getClassNames()} categoryName={categoryName} categoryColour={categoryColour} title={this.props.title} summary={this.props.summary} headerImageOrVideo={this.showHeaderImageOrVideo()} shareButtons={this.showShareButtons(headerSocial, true)} actionButton={this.props.actionButton}/>);
        }
    }
}
SummaryCard.defaultProps = {
    summary: "<span></span><span></span><span></span>",
    headerType: "regular",
    isSPA: true
};
export default SummaryCard;
