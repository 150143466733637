/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import RichTextDisplay from "./RichTextDisplay";
import { Map } from "immutable";
import { colors, mediaFeatures } from "../styles/common";
const metadataForRichText = new Map({
    metadata: new Map({
        align: "left"
    })
});
const TitleStyle = () => `
	& span {
		font-size: 19px;
		margin-bottom: 30px;
		margin-top: 30px;
		font-weight: bold;
		color: ${colors.descriptionColor};
		display: block;
		@media screen and ${mediaFeatures["for-large-tablet"]} {
			font-size: 21px;
		}
	}
`;
const RichTextTitle = (props) => {
    return (<RichTextDisplay metaClass="custom-widget-title" data={metadataForRichText.set("content", `<span>${props.text}</span>`)} cssStyle={css(TitleStyle())}/>);
};
export default RichTextTitle;
