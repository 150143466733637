import React from "react";
const NewShareIcon = (props) => {
    const { fill = '#0C172F' } = props;
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="#E7E8EA"/>
            <g id="EDP changes">
                <path d="M-2020 -1123C-2020 -1178.23 -1975.23 -1223 -1920 -1223H6380C6435.23 -1223 6480 -1178.23 6480 -1123V3677C6480 3732.23 6435.23 3777 6380 3777H-1920C-1975.23 3777 -2020 3732.23 -2020 3677V-1123Z" fill="#E7E8EA"/>
                <path d="M-1920 -1173H6380V-1273H-1920V-1173ZM6430 -1123V3677H6530V-1123H6430ZM6380 3727H-1920V3827H6380V3727ZM-1970 3677V-1123H-2070V3677H-1970ZM-1920 3727C-1947.61 3727 -1970 3704.61 -1970 3677H-2070C-2070 3759.84 -2002.84 3827 -1920 3827V3727ZM6430 3677C6430 3704.61 6407.61 3727 6380 3727V3827C6462.84 3827 6530 3759.84 6530 3677H6430ZM6380 -1173C6407.61 -1173 6430 -1150.61 6430 -1123H6530C6530 -1205.84 6462.84 -1273 6380 -1273V-1173ZM-1920 -1273C-2002.84 -1273 -2070 -1205.84 -2070 -1123H-1970C-1970 -1150.61 -1947.61 -1173 -1920 -1173V-1273Z" fill="#52CC6A"/>
                <g id="EDP / ABOUT (Mobile)" clip-path="url(#clip0_1_5287)">
                    <rect width="375" height="812" transform="translate(-30 -604)" fill="white"/>
                    <g id="Container">
                        <g id="Content">
                            <rect width="375" height="2840.56" transform="translate(-30 -524)" fill="#FAFAFA"/>
                            <g id="Frame 1437256790">
                                <g id="Frame 1261158064">
                                    <rect x="-15" y="-23" width="345" height="70" rx="16" fill="#F3F3F5"/>
                                    <g id="Frame">
                                        <path id="Vector" d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path id="Vector_2" d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path id="Vector_3" d="M22 21.0009V19.0009C21.9993 18.1146 21.7044 17.2536 21.1614 16.5532C20.6184 15.8527 19.8581 15.3524 19 15.1309" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path id="Vector_4" d="M16 3.13086C16.8604 3.35116 17.623 3.85156 18.1676 4.55317C18.7122 5.25478 19.0078 6.11769 19.0078 7.00586C19.0078 7.89403 18.7122 8.75694 18.1676 9.45855C17.623 10.1602 16.8604 10.6606 16 10.8809" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1_5287">
                    <rect width="375" height="812" fill="white" transform="translate(-30 -604)"/>
                </clipPath>
            </defs>
        </svg>);
};
export default NewShareIcon;
