/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Component } from "react";
import Image from "./Image";
class Advertisement extends Component {
    render() {
        const { autoPlay, bannerImage, className, href, imgWidth, imgWidths, lazy, loop, onClick, showAdLabel, trackingImage, style, dataRef, target = "_blank" } = this.props;
        if (!bannerImage) {
            return null;
        }
        // TODO use TrackingImage component
        return (<a data-testid="ad-banner-link" css={style} rel="noopener" target={target} href={href} className={className} onClick={onClick}>
				<Image src={bannerImage} alt={""} imgWidth={imgWidth} imgWidths={imgWidths} lazy={lazy} loop={loop} autoPlay={autoPlay} dataRef={dataRef}/>
				{showAdLabel && <div className="advertisement-label">Ad</div>}
				{trackingImage && (<img src={trackingImage} border={0} height={0} width={0} alt="Advertisement" className={"advertisement hidden"} data-ref={dataRef}/>)}
			</a>);
    }
}
Advertisement.defaultProps = {
    showAdLabel: true
};
export default Advertisement;
