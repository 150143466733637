/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component } from "react";
import classNames from "classnames";
import theme, { colors } from "../styles/common";
import InsiderPassLink from "./InsiderPassLink";
import DateIcon from "./ui/icons/EDPIcons/DateIcon";
import TagIcon from "./ui/icons/EDPIcons/TagIcon";
import VenueIcon from "./ui/icons/EDPIcons/VenueIcon";
import PhysicalVenueIcon from "./ui/icons/EDPIcons/PhysicalVenueIcon";
import { capitalizeFirstLetter } from "../helpers/constants";
import EDPShare from "./EDPShare";
import { detailCard, headerSummaryStyles, standaloneDetail } from "../styles/modules/headerCards";
import InsiderLoadable from "./InsiderLoadable";
import { DATA_REFS } from "../helpers/constants/dataRefs";
import TicketDeliveryTracker from "./EventDetailLayout/TicketDeliveryTracker";
import { isH5Build } from "../helpers/misc";
const EDPReportSection = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "EDPReportSection" */ "./EDPReportSection")
});
class SummaryCardDetail extends Component {
    render() {
        const summaryDetailBlock = `
			hr {
				width: 100%;
				height: 1px;
				margin: 15px 0;
				background: ${colors.borderColor};
        		border: 0;
				${theme.mediaQueries["for-mobile-and-tablet"]} {
					display: none;
				}
			}
			${theme.mediaQueries["for-large-tablet"]}{
				padding: 18px;
				border-radius: 12px;
				border: 1px solid ${colors.borderColor};
				background: white;
			};
		}`;
        const categoryNameStyle = `
			font-size: 14px;
			line-height: 16px;
			color: ${colors.descriptionColor};
			font-weight: 500;
		`;
        const dateStringStyle = `
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: ${colors.descriptionColor};
		`;
        const titleStyle = `
			font-size: 21px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 29px;
			letter-spacing: normal;
			color: ${colors.headingBlack};
			padding-right: 12px;
			word-break: break-word;
			${theme.mediaQueries["for-mobile"]}{
				font-size: 21px;
				line-height: 22px;
				flex: 4
			};
		`;
        const shareStyle = `
			display: ${this.props.standalone ? `none` : `flex`};
			position:relative;
			// margin:18px;
			// margin-top:30px;
			${theme.mediaQueries["for-pc"]} {
				display: flex;
			};
		`;
        const dateStyle = `
			display: flex;
			align-items: center;
			margin: 12px 0 30px;
			${theme.mediaQueries["for-mobile"]} {
				margin: 12px 0px 0px;
			};
			${theme.mediaQueries["for-tablet"]} {
				margin: 12px 0px;
			};
		}`;
        const SalientFeaturesStyle = `
			display: none;
			${theme.mediaQueries["for-pc"]} {
				margin-top: 18px;
				padding: 18px;
				border-radius: 12px;
				border: 1px solid ${colors.borderColor};
				display: block;
				background: white;
			};
		}`;
        const favButtonStyle = `
			flex: 1; 
		`;
        const DisplayForPc = `
			display:block;
			${theme.mediaQueries["for-mobile-and-tablet"]} {
				display: none;
			};
		`;
        const IconStyle = `margin-right: 12px;`;
        const CategoryNameWrapper = `
			display: flex;
			align-items: center;
			margin-top: 18px;
			${theme.mediaQueries["for-mobile"]} {
				margin-top: 22px;
			}
		`;
        const { actionButton, shareButtons, salient_features, showAsyncFavouriteButton, prerequisites, type, shouldSkipShareSection, shouldSkipPrerequisitiesSection, shouldSkipVenueSection, reportEventHandler, isFromApp, queryParams, shouldSkipReportSection, displayMode, isLoggedIn, shouldDisplayDeliveryTrackerCard, event_id, shouldShowPrerequisitiesSection } = this.props;
        const displayTracker = ((isLoggedIn && !isFromApp) || isH5Build) && shouldDisplayDeliveryTrackerCard;
        return (<div className={classNames(this.props.shellClass)} css={css([
                {
                    [theme.mediaQueries["for-mobile"]]: {
                        marginBottom: "30px !important"
                    }
                },
                detailCard,
                this.props.standalone && standaloneDetail
            ])}>
				<div css={css(summaryDetailBlock)}>
					<div css={{ display: "flex" }}>
						<div>
							<h1 className={classNames(this.props.shellClass)} css={css(titleStyle)} data-ref={`${DATA_REFS.EDP_EVENT_TITLE}_${displayMode}`}>
								{this.props.title}
							</h1>
							<div css={css(CategoryNameWrapper)}>
								<div css={css(IconStyle)}>
									<TagIcon />
								</div>
								<p css={css(categoryNameStyle)} data-ref={`${DATA_REFS.EDP_EVENT_CATEGORY}_${displayMode}`}>{this.props.categoryName}</p>
							</div>
						</div>

						{/* {showAsyncFavouriteButton && <div css={css(favButtonStyle)}>{showAsyncFavouriteButton}</div>} */}
					</div>
					{this.props.event_date ? (<div>
							<div css={css(dateStyle)}>
								<div css={css(IconStyle)}>
									<DateIcon />
								</div>
								<p css={css(dateStringStyle)} data-ref={`${DATA_REFS.EDP_EVENT_DATESTRING}_${displayMode}`}>{this.props.event_date}</p>
							</div>
							{!shouldSkipVenueSection && (<div css={css(dateStyle)}>
								<div css={css(IconStyle)}>{type === "online" ? <VenueIcon /> : <PhysicalVenueIcon />}</div>
								<p css={css(dateStringStyle)} data-ref={`${DATA_REFS.EDP_EVENT_VENUE}_${displayMode}`}>{this.props.venue}{(type === "physical" && this.props.city) ? `, ${capitalizeFirstLetter(this.props.city)}` : ''}</p>
							</div>)}
						</div>) : (<div css={css(headerSummaryStyles)} className={classNames(this.props.summaryType, this.props.shellClass)} dangerouslySetInnerHTML={{ __html: this.props.summary }}/>)}
					<hr />
					{actionButton}
				</div>
			
				{shouldShowPrerequisitiesSection && prerequisites && prerequisites.length > 0 && (<div css={css(DisplayForPc)}>
						<InsiderPassLink prerequisites={prerequisites} event_id={event_id}/>
					</div>)}
				{salient_features && <div css={css(SalientFeaturesStyle)}>{salient_features}</div>}
				{displayTracker && <TicketDeliveryTracker />}

				{!shouldSkipShareSection && (<div css={css(shareStyle)}>
						<EDPShare showShareButtons={shareButtons}/>
					</div>)}

				{!shouldSkipReportSection && (<div css={css(DisplayForPc)}>
						<EDPReportSection reportEventHandler={reportEventHandler} isFromApp={isFromApp} queryParams={queryParams}/>
					</div>)}
			</div>);
    }
}
SummaryCardDetail.defaultProps = {
    summary: "<span></span><span></span><span></span>",
    isSPA: true,
    standalone: false,
    shouldSkipReportSection: true
};
export default SummaryCardDetail;
