import React from "react";
export const DirectionIcon = (props) => {
    const { color = "#0C172F" } = props;
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_709_131" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
				<path d="M16.4393 11.2175C16.4872 11.2655 16.5234 11.3207 16.5478 11.3797C16.572 11.4382 16.5855 11.5023 16.5857 11.5696L16.5857 11.5711L16.5857 11.5726C16.5853 11.6976 16.5383 11.8226 16.4447 11.9191L16.4387 11.9252L14.4393 13.9246C14.244 14.1199 13.9274 14.1199 13.7322 13.9246C13.5369 13.7294 13.5369 13.4128 13.7322 13.2175L14.8786 12.0711H11.0857C10.8096 12.0711 10.5857 12.2949 10.5857 12.5711V14.5711C10.5857 14.8472 10.3619 15.0711 10.0857 15.0711C9.80957 15.0711 9.58571 14.8472 9.58571 14.5711V12.5711C9.58571 11.7426 10.2573 11.0711 11.0857 11.0711L14.8786 11.0711L13.7322 9.92462C13.5369 9.72936 13.5369 9.41278 13.7322 9.21751C13.9274 9.02225 14.244 9.02225 14.4393 9.21751L16.4393 11.2175Z" fill={color}/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M3.93934 11.0104C3.35355 11.5962 3.35355 12.5459 3.93934 13.1317L11.0104 20.2028C11.5962 20.7886 12.5459 20.7886 13.1317 20.2028L20.2028 13.1317C20.7886 12.5459 20.7886 11.5962 20.2028 11.0104L13.1317 3.93934C12.5459 3.35355 11.5962 3.35355 11.0104 3.93934L3.93934 11.0104ZM4.64645 12.4246C4.45118 12.2294 4.45118 11.9128 4.64645 11.7175L11.7175 4.64645C11.9128 4.45118 12.2294 4.45118 12.4246 4.64645L19.4957 11.7175C19.691 11.9128 19.691 12.2294 19.4957 12.4246L12.4246 19.4957C12.2294 19.691 11.9128 19.691 11.7175 19.4957L4.64645 12.4246Z" fill={color}/>
			</mask>
			<g mask="url(#mask0_709_131)">
				<rect width="24" height="24" fill={color}/>
			</g>
		</svg>);
};
