/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import { InterFont, mediaFeatures } from "../styles/common";
import { colors } from "../styles/common";
const AboutShellHeading = `
	font-size: 21px;
	margin-top: 30px;
    margin-bottom: 30px;
`;
const DataLoadingAnimation = keyframes `
	0% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} -50%, ${colors["lighter-gray"]} -20%);
	}
	6% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} -40%, ${colors["lighter-gray"]} -10%);
	}
	12% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} -30%, ${colors["lighter-gray"]} 0%);
	}
	17% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} -20%, ${colors["lighter-gray"]} 10%);
	}
	23% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} -10%, ${colors["lighter-gray"]} 20%);
	}
	29% { 
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} 0%, ${colors["lighter-gray"]} 30%);
	}
	35% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} 10%, ${colors["lighter-gray"]} 40%);
	}
	41% {
		background: linear-gradient(to right, ${colors["slightly-lighter-gray"]} 20%, ${colors["lighter-gray"]} 50%);
	}
	47% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 0%, ${colors["slightly-lighter-gray"]} 30%, ${colors["lighter-gray"]} 60%);
	}
	54% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 10%, ${colors["slightly-lighter-gray"]} 40%, ${colors["lighter-gray"]} 70%);
	}
	59% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 20%, ${colors["slightly-lighter-gray"]} 50%, ${colors["lighter-gray"]} 80%);
	}
	65% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 30%, ${colors["slightly-lighter-gray"]} 60%, ${colors["lighter-gray"]} 90%);
	}
	71% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 40%, ${colors["slightly-lighter-gray"]} 70%, ${colors["lighter-gray"]} 100%);
	}
	76% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 50%, ${colors["slightly-lighter-gray"]} 80%, ${colors["lighter-gray"]} 110%);
	}
	80% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 60%, ${colors["slightly-lighter-gray"]} 90%, ${colors["lighter-gray"]} 120%);
	}
	85% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 70%, ${colors["slightly-lighter-gray"]} 100%, ${colors["lighter-gray"]} 130%);
	}
	91% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 80%, ${colors["slightly-lighter-gray"]} 110%);
	}
	96% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 90%, ${colors["slightly-lighter-gray"]} 120%);
	}
	100% {
		background: linear-gradient(to right, ${colors["lighter-gray"]} 100%, ${colors["slightly-lighter-gray"]} 130%);
	}
`;
const AboutShellText = css `
	margin-left: 10vw;
	font-family: ${InterFont};
	color: #373435;
	margin-bottom: 2.5vw;
	margin-top: 2.5vw;
	width: 52.5vw;
	span {
		height: 14px;
		margin-bottom: 14px;
		display: block;
		border-radius: 20px;
		background: linear-gradient(90deg, ${colors["slightly-lighter-gray"]} 0, ${colors["lighter-gray"]} 30%);
		animation: ${DataLoadingAnimation} 0.75s ease-in-out infinite;
		:nth-of-type(2) {
			width: 95%;
		}
		:nth-of-type(3n) {
			width: 85%;
		}
		:nth-of-type(4n) {
			width: 75%;
		}
		:nth-of-type(5) {
			width: 80%;
		}
	}
	@media screen and ${mediaFeatures["for-mobile"]} {
		width: 88vw;
		margin-left: auto;
		margin-right: auto;
		margin-top: 4vw;
		span {
			display: block;
			margin-bottom: 10px;
			height: 10px;
			width: 90%;
			border-radius: 20px;
		}
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		width: 84vw;
		margin-top: 3vw;
		margin-bottom: 3vw;
		margin-left: auto;
		margin-right: auto;
		span {
			display: block;
			margin-bottom: 10px;
			height: 10px;
			width: 90%;
			border-radius: 20px;
		}
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		margin-left: calc((100vw - 1280px) / 2);
		width: 840px;
		margin-bottom: 40px;
		margin-top: 40px;
		span {
			height: 14px;
			margin-bottom: 14px;
		}
	}
`;
const AboutShell = props => (<section css={AboutShellText}>
		<h1 css={css(AboutShellHeading)}>About the Event</h1>
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
	</section>);
export default AboutShell;
