import React, { Component } from "react";
import ActiveOffersWithSubscription from "../../containers/ActiveOffersWithSubscription";
import { sendClevertapEvent } from "../../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../../helpers/constants";
class ActiveOffersContainers extends Component {
    constructor(props) {
        super(props);
        this.sendCodeCopiedToClevertap = (copiedCoupon) => {
            if (this.props.eventName) {
                sendClevertapEvent(CLEVERTAP_ACTIONS.EDP_COUPON_COPIED, {
                    event_name: this.props.eventName,
                    coupon_copied: copiedCoupon
                });
            }
        };
        this.notify = (couponCode) => {
            const { clearNotifications, notify } = this.props;
            // remove any existing notifications
            clearNotifications();
            notify([
                {
                    message: `Yay! '${couponCode}' has been copied to your clipboard, apply it at checkout.`,
                    autoDismiss: 3,
                    level: "success",
                    onRemove: () => clearNotifications(),
                    uid: couponCode,
                    position: "tc"
                }
            ]);
            // Send to Clevertap
            this.sendCodeCopiedToClevertap(couponCode);
        };
    }
    componentDidMount() {
        this.props.getDiscountOffer();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.offerConditions != this.props.offerConditions) {
            this.props.getDiscountOffer();
        }
    }
    render() {
        let { offers } = this.props;
        if (offers && offers.size > 0) {
            return <ActiveOffersWithSubscription activeOffers={offers} onCopyToClipboard={this.notify}/>;
        }
        else {
            return null;
        }
    }
}
export default ActiveOffersContainers;
