/** @jsx jsx */
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { jsx, css } from "@emotion/core";
const closeButton = css `
	background-image: url("/assets/images/icons/cross-black.svg");
	top: 2%;
	right: 1%;
	background-color: transparent;
`;
class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.handleDismiss = e => {
            if (e.target.id !== "modalOverlay" && e.target.id !== "closeModalButton")
                return;
            // if cant close the dialogue box..for clicks outside ui do nothing
            if (!this.props.canClose) {
                if (e.target.id !== "modalUi")
                    return;
            }
            if (this.props.handleDismiss) {
                // notify parent
                this.props.handleDismiss();
            }
            this.setState({
                dismissed: true
            });
        };
        this.state = {
            // initially
            dismissed: false
        };
    }
    /*
    shouldComponentUpdate(nextProps, nextState) {
        // if modal has been dismissed once, then don't
        // update at all, even if parent has triggered it
        if (this.state.dismissed) {
            return false;
        }
        // if the modal is dismissed
        if (this.state.dismissed !== nextState.dismissed) {
            return true;
        }
        // when screen type has been changed render new screen type
        return this.props.screen !== nextProps.screen;
        // lastly if parent updates modal state
        return this.props.shouldDisplay !== nextProps.shouldDisplay;
    }
 */
    render() {
        const { canClose } = this.props;
        // first priority is if this modal has been dimissed
        if (this.state.dismissed) {
            return null;
        }
        // if parent says don't display
        if (!this.props.shouldDisplay) {
            return null;
        }
        // modal overlay is the complete page
        return (<div id="modalOverlay" className="page-overlay" onClick={this.handleDismiss}>
				<div className={classNames("standard-modal", this.props.modalType)} id="modalUi" role="dialog" css={this.props.customStyle} key={this.props.modalKey}>
					{this.props.children}
					{canClose ? (<button id="closeModalButton" onClick={this.handleDismiss} css={this.props.closeButton} className="icon-round icon-close" role="close"/>) : null}
				</div>
			</div>);
    }
}
Modal.propTypes = {
    // Warning: react-ouibounce is hardcoded to use shouldDisplay
    // If you change this key ExitIntent will break
    shouldDisplay: PropTypes.bool.isRequired,
    // if can close is false...dont show close button and click outside modal ui should not do anything
    canClose: PropTypes.bool,
    closeButton: PropTypes.any
};
Modal.defaultProps = {
    shouldDisplay: false,
    canClose: true,
    closeButton: closeButton
};
Modal.defaultProps = {
    modalType: "",
    canClose: true,
    closeButton: closeButton
};
export default Modal;
