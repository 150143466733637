import React from "react";
export default class TimerIcon extends React.Component {
    render() {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
				<g fill="none" fill-rule="evenodd" transform="translate(0 1)">
					<rect width="5" height="2" x="5.5" stroke="#0C172F" rx=".5"/>
					<circle cx="8" cy="11" r="7.5" fill="#FFF" fill-rule="nonzero" stroke="#0C172F"/>
					<rect width="4" height="1" x="13.495" y="5.456" fill="#0C172F" fill-rule="nonzero" rx=".5" transform="rotate(55.494 15.495 5.956)"/>
					<path stroke="#0C172F" stroke-linecap="round" d="M8 11L5 8M8 17L8 16M14 11L13 11M8 5L8 6M2 11L3 11"/>
				</g>
			</svg>);
    }
}
