/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import Image from "./Image";
import BuyButton from "./BuyButton";
import InsiderLoadable from "./InsiderLoadable";
import theme, { colors } from "../styles/common";
import TimerIcon from "./ui/icons/EDPIcons/TimerIcon";
import { buyButtonColorStyle, fontStyle } from "./EDPStyles";
const RemindMeButton = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "RemindMeButton" */ "../containers/RemindMeButtonWithSubscription")
});
const pad = v => {
    return v < 10 ? "0" + v : v;
};
const buyButtonPriceLayout = `
	display: block;
	flex: 1;
	padding: 0px;
	margin: auto;
	${theme.mediaQueries["for-pc"]} {
		flex: 5;
	};
`;
const IconStyle = `
	display: flex;
	align-items: center;
	margin-right: 8px;
`;
const priceDescStyle = `
	font-size: 12px;
	line-height: 1.17;
	color: ${colors.subTextGrey};
	${"& a"}{
		color: ${colors.primaryBlue};
	}; 
`;
const buttonStyle = `
	width: 100%;
	color: white;
	font-family: Bebas Neue, sans-serif;
	font-weight: 400;
	text-align: center;
	font-size: 12px;
	padding: 8px 18px;
	max-height: 42px;
	white-space: nowrap;
	${theme.mediaQueries["for-mobile"]} {
		padding: 6px 24px;
	};
	${theme.mediaQueries["for-tablet-and-pc"]} {
		padding: 8px;
	};
	border-radius: 6px;
	cursor: pointer;
	background-color: ${colors.primaryPink};
	`;
const numberFont = `
	font-size: 21px;
	${theme.mediaQueries["for-mobile"]} {
		font-size: 24px;
		
	};
	${theme.mediaQueries["for-tablet-and-pc"]} {
		font-size: 16px;
		
	};
`;
const timerLayout = `display: flex;
	flex: 1;
	border-radius: 6px;
	${theme.mediaQueries["for-pc"]} { 
		flex: 3;
	};`;
export default class EventCountdownTimer extends React.Component {
    constructor(props) {
        super(props);
        this.tick = this.tick.bind(this);
        this.state = {
            dummy: true
        };
    }
    componentDidMount() {
        this.tick();
    }
    reloadEventData() {
        this.props.fetchEventDetails({ slug: this.props.slug }).catch(e => {
            // unsure if this is the place to deal with the error
            window.location.href = "https://insider.in/not-found";
        });
    }
    tick() {
        // avoid extra rendering when timer runs down.
        if (this.props.show_timer && Date.now() - 1000 < this.props.timestamp_live * 1000) {
            this.setState({
                dummy: !this.state.dummy
            });
            setTimeout(this.tick, 1000);
        }
        else if (this.props.show_timer &&
            Date.now() > this.props.timestamp_live * 1000 - 1 * 1000 &&
            Date.now() < this.props.timestamp_live * 1000 + 2000) {
            window.location.reload();
        }
    }
    countDown() {
        let reminder = this.props.timestamp_live - parseInt(Date.now() / 1000);
        const days = parseInt(reminder / 86400);
        reminder = reminder % 86400;
        const hours = pad(parseInt(reminder / 3600));
        reminder = reminder % 3600;
        const minutes = pad(parseInt(reminder / 60));
        const seconds = pad(reminder % 60);
        return {
            days,
            hours,
            minutes,
            seconds
        };
    }
    render() {
        const buyButtonLayoutStyle = `
			alignSelf: flex-end;
			display: flex;
			background-color: white;
			z-index: 10;
			${theme.mediaQueries["for-mobile"]}{
				display: ${this.props.type === "top" ? "none" : "flex"};
				position: sticky;
				bottom: 0;
				box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1);
				padding: 12px 6vw;
			};
			${theme.mediaQueries["for-tablet"]}{
				display: ${this.props.type === "bottom" ? "none" : "flex"};
			};
		`;
        if (this.props.show_timer && Date.now() < this.props.timestamp_live * 1000) {
            const { days, hours, minutes, seconds } = this.countDown();
            return (<div css={css(buyButtonLayoutStyle)}>
					<div css={css(buyButtonPriceLayout)}>
						<div css={{ display: "flex", paddingRight: 9 }}>
							<div css={css(IconStyle)}>
								<TimerIcon />
							</div>
							<div css={{ display: "block", margin: "auto 0" }}>
								<p css={{ fontWeight: 500, fontSize: 16, lineHeight: 1.45 }}>Goes live in</p>
							</div>
						</div>
					</div>
					<div css={css(timerLayout)}>
						<div css={[css(buttonStyle), buyButtonColorStyle, fontStyle]}>
							<p>
								<span css={css(numberFont)}>{days}</span>d<span css={css(numberFont)}>{hours}</span>h
								<span css={css(numberFont)}>{minutes}</span>m<span css={css(numberFont)}>{seconds}</span>s
							</p>
						</div>
					</div>
				</div>);
        }
        {
            return this.props.communication_strategy === "reminder" ? (<RemindMeButton {...this.props}/>) : (<BuyButton {...this.props}/>);
        }
    }
}
EventCountdownTimer.propTypes = {};
EventCountdownTimer.defaultProps = {};
