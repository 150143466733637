/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { LinkWithH5Support } from "./LinkWithH5Support";
import { XDarkIcon } from "./ui/icons/XDarkIcon";
import { dimensions, flexBox } from "./ui-lib/Atoms/Typography/stylesUtils";
const constructTwitterWebIntent = ({ text, url }) => {
    const tweetString = `text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}&via=paytminsider`;
    return `https://twitter.com/intent/tweet?${tweetString}`;
};
const TwitterShareButton = (props) => (<LinkWithH5Support 
// className={classNames("social-button social-twitter", {
// 	large: props.large,
// 	block: props.block
// })}
href={constructTwitterWebIntent({
        text: props.text,
        url: props.url
    })} onClick={() => {
        ga("send", {
            hitType: "event",
            eventCategory: "TwitterShare",
            eventAction: props.position,
            eventLabel: window.location.pathname
        });
        props.sendEdpShareFallbackEvent("Twitter", props.url, props.name);
    }} target="_blank">
		<div css={css([iconCont])}>
			<XDarkIcon fillColor='#0C172F'/>
		</div>
	</LinkWithH5Support>);
TwitterShareButton.defaultProps = {
    position: "default",
    large: false,
    block: false
};
export default TwitterShareButton;
const iconCont = `
	${flexBox('center', 'center')}
	img, svg {
		${dimensions('24px', '24px')}
	}
`;
