/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useCallback, useMemo, useState } from "react";
import Image from "./ui-lib/newCompo/Image";
import theme, { colors } from "../styles/common";
import { DIGITAL_EVENTS_ICON } from "../helpers/constants/common";
import { BackgroundColor, dimensions, flexBox, FontColor, getFont } from "./ui-lib/Atoms/Typography/stylesUtils";
import InfoIcon from "./ui/icons/InfoIcon";
import { ChevronRight } from "./ui/icons/ChevronRight";
import BottomSheet from "./BottomSheetModal";
const SalientFeatures = (props) => {
    const { salient_features, venue, type } = props;
    const [showFullList, setShowFullList] = useState(false);
    const salientFeaturesLayout = `
		${flexBox('flex-start', 'flex-start')}
		gap: 12px;
		flex: 1;
    `;
    const salientFeaturesTitle = `
		${getFont('12px', 400)}
		${FontColor('--Grey-Small-Copy')}
    `;
    const salientFeaturesDescription = `
		${getFont('14px', 500)}
		${FontColor('--grey-highlight-copy')}
		margin-top: 4px;
    }`;
    const FeatureLayout = `
	 	${flexBox('space-between', 'flex-start')}
		gap: 12px;
        ${theme.mediaQueries["for-mobile"]}{
            flex-direction: column;
        };
    `;
    const seperatorStyle = `
        ${theme.mediaQueries["for-mobile"]}{
            display:none;
        };
        height: 1;
        border: solid 0.5px ${colors.faintGrey};
    `;
    const headingStyle = `
		${flexBox('space-between', 'flex-start')}
    	padding-bottom: 15px;
		p {
			font-size: 16px;
			font-weight: 700;
		}
		hr {
        	height: 1px;
        	width: 100%;
        	border: 0;
        	margin: 15px 0;
        	background: ${colors.faintGrey};
    	}
    `;
    const edpIconStyle = `
		${flexBox('center', 'center')}
		border-radius: 12px;
		padding: 12px;
		
		${BackgroundColor('--Background-BG-SecBTN-Light')}
		div	{
			overflow-y: hidden;
		}
		img, svg	{
			width: 24px;
			height: 24px;
			flex-shrink: 0;
		}
		// svg {
		// 	width: 28px;
		// 	height: 28px;
		// }
	`;
    const featureInJs = useMemo(() => {
        var _a;
        return (_a = salient_features === null || salient_features === void 0 ? void 0 : salient_features.toJS()) !== null && _a !== void 0 ? _a : [];
    }, [salient_features]);
    const getFeatures = useMemo(() => {
        let features = [...featureInJs];
        const isPhysicalEvent = type === "physical";
        if (!isPhysicalEvent) {
            features.push({
                title: 'Digital Event',
                text: venue,
                image_url: DIGITAL_EVENTS_ICON
            });
        }
        return features;
    }, [featureInJs, type]);
    const getTopThreeFeature = useMemo(() => {
        return getFeatures.slice(0, 3);
    }, [getFeatures]);
    const getText = useCallback((item) => {
        var _a;
        const text = item === null || item === void 0 ? void 0 : item.text;
        const type = ((_a = item === null || item === void 0 ? void 0 : item.type) !== null && _a !== void 0 ? _a : "").toLowerCase();
        switch (type) {
            case 'age': {
                let displaytext = text;
                const isNumber = !isNaN(Number(text));
                if (isNumber)
                    displaytext = `${text} yrs & above`;
                else if (text.includes('+'))
                    displaytext = `${text} & above`;
                return `${displaytext}`;
            }
            default: {
                return text;
            }
        }
    }, []);
    const getIcon = useCallback((featureType, icon_url) => {
        const type = (featureType !== null && featureType !== void 0 ? featureType : '').toLowerCase();
        switch (type) {
            // case 'language': {
            // 	return <LanguageIcon fill='#98989F' />
            // }
            // case 'age': {
            // 	return <InfoIcon fill='#98989F' />
            // }
            default: {
                if (icon_url)
                    return <Image src={icon_url} style='background: transparent;' alt="icon" fallbackCompo={<InfoIcon fill='#98989F'/>}/>;
                return <InfoIcon fill='#98989F'/>;
            }
        }
    }, []);
    const dataCards = (salient_features) => {
        const data = salient_features.map((feature, i) => {
            var _a, _b, _c;
            const featureInJs = feature;
            const title = (_a = featureInJs === null || featureInJs === void 0 ? void 0 : featureInJs.title) !== null && _a !== void 0 ? _a : '';
            const type = (_b = feature === null || feature === void 0 ? void 0 : feature.type) !== null && _b !== void 0 ? _b : '';
            const imageUrl = (_c = feature === null || feature === void 0 ? void 0 : feature.image_url) !== null && _c !== void 0 ? _c : '';
            const text = getText(featureInJs);
            return (<div key={`salient-features-${i}`} css={[css(salientFeaturesLayout)]}>
					<figure css={css(edpIconStyle)}>
						{featureInJs.icon ? featureInJs.icon :
                    getIcon(type, imageUrl)}
					</figure>
					<div>
						<p css={css(salientFeaturesTitle)}>{title}</p>
						<p css={css(salientFeaturesDescription)}>{text}</p>
					</div>
				</div>);
        });
        return data;
    };
    return (<React.Fragment>
			<div css={css(headingStyle)}>
				<p>Event Guide</p>
				{getFeatures && getFeatures.length > 3 &&
            <div css={css([showViewAllCont])} onClick={() => setShowFullList(true)}>
                    	<p>See all</p>
                    	<ChevronRight fill={colors['--grey-highlight-copy']}/>
                	</div>}
			</div>
			<div css={css(FeatureLayout)}>
				{/* first is always venue
        {!isPhysicalEvent && (<div css={[css(salientFeaturesLayout), { paddingBottom: 30 }]}>
            <Image css={css(edpIconStyle)} src={DIGITAL_EVENTS_ICON} />
            <div>
                <p css={css(salientFeaturesTitle)}>Digital Event</p>
                <p css={css(salientFeaturesDescription)}>{venue}</p>
            </div>
        </div>)} */}
				{dataCards(getTopThreeFeature)}
			</div>
			{showFullList && <BottomSheet handleDismiss={() => setShowFullList(false)}>
                <div css={css([bottomSheetHeadingCont])}>
					<p>Event Guide</p>
					<hr />
                </div>
                <div css={css(FeatureLayout, 'flex-direction: column;', maxheightCont)}>
					{dataCards(getFeatures)}
                </div>
			</BottomSheet>}
		</React.Fragment>);
};
export default SalientFeatures;
const showViewAllCont = `
    ${flexBox('flex-start', 'center')}
    gap: 4px;
    p {
        ${getFont('14px', 600)}
        ${FontColor('--grey-highlight-copy')}
    }
    svg, img {
        ${dimensions("16px", "16px")}
    }
	&:hover {
       	cursor: pointer;
    }
`;
const maxheightCont = `
    max-height: 70vh;
    overflow-y: scroll;
`;
const bottomSheetHeadingCont = `
	p {
		${getFont('21px', 700)}
    	${FontColor('--grey-highlight-copy')}
		padding-bottom: 15px;
	}
	hr {
		height: 1px;
		width: 100%;
		border: 0;
		margin: 15px 0;
		background: ${colors.faintGrey};
	}
    ;
`;
