/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState } from "react";
import InsiderLink from "../../../../containers/InsiderLinkWithSubscription";
// import { colors } from "../../Atoms/Typography/styles";
import { flexBox, FontColor, dimensions, getFont, ellipsisLine } from "../../Atoms/Typography/stylesUtils";
const Artist = (props) => {
    const { cast: { handleClick, description, imageUrl, name, href }, showReadMore = false, limit = 50, onShowAllClick, rowView } = props;
    const [showAll, setShowAll] = useState(false);
    let shortSubtitle = description;
    let shouldShorten = false;
    if (showReadMore) {
        shortSubtitle = (description !== null && description !== void 0 ? description : '').slice(0, limit);
        shouldShorten = (description !== null && description !== void 0 ? description : '').length > limit;
        if (shouldShorten)
            shortSubtitle += `...`;
    }
    const readMoreClick = (e) => {
        e.stopPropagation();
        if (onShowAllClick)
            onShowAllClick();
        else {
            setShowAll(!showAll);
        }
    };
    return (<InsiderLink activeClassName="active" href={href}>
    <div onClick={handleClick} css={css([movieCast(rowView)])} role={"img"} aria-label={name}>
      <div css={css([imgCon])}>
        <img css={css([imgStyle])} alt={name} src={imageUrl}/>
      </div>
      <div>
        <div>{name}</div>
       {description && <p css={css([subtext])}>{description}
            {/* {showAll ? description : shortSubtitle}
            {shouldShorten &&
                <span onClick={readMoreClick} css={css([readMoreStyle])}>
                    {showAll ? "Read less" : "Read more"}
                </span>} */}
        </p>}
      </div>
    </div>
    </InsiderLink>);
};
export default Artist;
const movieCast = (rowView) => `
  ${rowView ? `
        ${flexBox("flex-start", "center")}
        gap: 12px;
    ` :
    `
        ${flexBox("center", "center", "", "column")}
        text-align: center;
    `}
    ${getFont("14px", 600)}
    ${FontColor('--grey-highlight-copy')}
`;
const imgCon = `
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 8px;
    ${dimensions("120px", "120px")}
`;
const imgStyle = `
    ${dimensions("100%", "100%")}
`;
const subtext = `
    ${getFont('12px', 400)}
    ${FontColor('--grey-regular-copy')}
    ${ellipsisLine('2')}

    svg, img {
        ${dimensions('16px', '16px')}
    }
`;
// const readMoreStyle = `
//     ${getFont('--Typography-Font-Size-Body-sm', 400, '--Typography-Line-Height-L18', '--Typography-Letter-Spacing-Caption')}
//     ${FontColor('--Colors-Neutral-Grey-50')}
//     border-bottom: 1px dashed ${colors['--Colors-Neutral-Grey-50']};
//     width: min-content;
//     margin-left: 4px;
// `;
