/** @jsx jsx */
import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { jsx, css } from "@emotion/core";
import { flexBox } from '../../Atoms/Typography/stylesUtils';
import { mediaFeatures } from '../../../../styles/common';
const HorizontalGrid = ({ data = [], onClick, keyName = 'img' }) => {
    // data = data.splice(0, 5);
    const [groups, setGroups] = useState([]);
    const [dataFiltered, setDataFiltered] = useState(false);
    const [openFullPageCarousel, setOpenFullPageCarousel] = useState(false);
    const [selectedImage, setSelectedImage] = useState(-1);
    const getRandomType = useCallback((types, lastType) => {
        if (lastType === null) {
            return types[0];
        }
        let availableTypes = types.filter((type) => type !== lastType);
        return availableTypes[Math.floor(Math.random() * availableTypes.length)];
    }, []);
    const divideAndAssignTypes = useCallback((arr) => {
        // Divide the array into groups of 3, 2, 1
        let groups = [];
        let i = 0;
        let types = ['A', 'B', 'C'];
        let lastAssigned = null;
        while (i < arr.length) {
            if (arr.length - i >= 3) {
                const type = arr.length === 3 ? 'A' : getRandomType(types, lastAssigned);
                lastAssigned = type;
                groups.push({
                    images: arr.slice(i, i + 3),
                    type: type,
                    lastImageIndex: i,
                });
                i += 3;
            }
            else if (arr.length - i === 2) {
                groups.push({
                    images: arr.slice(i, i + 2),
                    type: 'A',
                    lastImageIndex: i,
                });
                i += 2;
            }
            else {
                groups.push({
                    images: arr.slice(i, i + 1),
                    type: 'A',
                    lastImageIndex: i,
                });
                i += 1;
            }
        }
        return groups;
    }, [getRandomType]);
    useEffect(() => {
        if (data.length > 3 && !dataFiltered) {
            let result = divideAndAssignTypes(data);
            console.log(result);
            setDataFiltered(true);
            setGroups(result);
        }
    }, [data, data.length, dataFiltered, divideAndAssignTypes]);
    const itemClickHandler = useCallback((item, index) => {
        console.log(index);
        setSelectedImage(index);
        setOpenFullPageCarousel(true);
        onClick(item, index);
    }, [onClick]);
    const getImage = useCallback((e) => {
        if (typeof e === 'string') {
            return e;
        }
        else {
            return e[keyName];
        }
    }, [keyName]);
    console.log(data, "data", groups);
    return (<React.Fragment>
		<div css={css([gridStyles])}>
			<div css={css([container])}>
				{data.length === 1 && (<img css={css([singleItem])} alt="" src={getImage(data[0])} onClick={() => itemClickHandler(data[0], 0)}/>)}
				{data.length === 2 && (<div css={css([row])}>
						<img alt="" src={getImage(data[0])} onClick={() => itemClickHandler(data[0], 0)}/>
						<img alt="" src={getImage(data[1])} onClick={() => itemClickHandler(data[1], 1)}/>
					</div>)}
				{data.length === 3 && (<div className={`grid_for_three`}>
						{data.map((group, index) => (<img key={index} alt="" src={getImage(group)} className={`image_${index + 1}`} onClick={() => itemClickHandler(data[index], index)}/>))}
					</div>)}
				{data.length > 3 && groups.length > 0 && (<Fragment>
						{groups.map((group, index) => {
                var _a;
                return (<div key={index} className={`grid_type_${(_a = group === null || group === void 0 ? void 0 : group.images) === null || _a === void 0 ? void 0 : _a.length}_${group === null || group === void 0 ? void 0 : group.type}`}>
								{group.images.map((image, id) => (<img key={id} alt="" src={getImage(image)} className={`image_${id + 1}`} onClick={() => itemClickHandler(image, group.lastImageIndex + id)}/>))}
							</div>);
            })}
					</Fragment>)}
			</div>
		</div>
		{/* {openFullPageCarousel &&
            <FullImageCarousel
                images={{imageUrls: data}}
                keyName='medium'
                selectedState={{index: selectedImage}}
                closePostersPopup={() => setOpenFullPageCarousel(false)}
            />} */}
	</React.Fragment>);
};
const border = `
	border-radius: 16px;
	// border: 0.7px solid rgb(255, 255, 255, 0.24);
	object-fit: cover;
`;
const grid = `
  display: grid;
	grid-template-columns: repeat(2, 1fr); // Two columns with specified width
	grid-template-rows: auto auto; // Two rows with automatic height
	gap: 12px;
	padding-left: 12px;
	// grid-template-rows: repeat(auto-fill, minmax(119.675px, auto));
`;
const imageProp = `
	width: 100%;
	height: auto;
	${border}
`;
const smallImageProp = `
	width: 156px;
	height: 156px;
	${border}
	 @media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		width: 119.675px;
		height: 119.675px;
	}

`;
const largeImageProp = `
	width: 324px;
	height: 324px;
	${border}
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		width: 252.337px;
		height: 252.337px;
	}
`;
const meidumImageProp = `
	width: 243px;
	height: 243px;
	${border}
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		width: 186px;
		height: 186px;
	}
`;
const singleImageProp = `
	${border}
	height: 492px;
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		height: 384px;
		width: 384px;
	}
`;
const container = `
  ${flexBox('flex-start', 'center', '', 'row')}
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
	margin: 0 -16px;
    padding-left: 16px;
    // img {
    //     min-width: 260px;
    // }
    
    // @media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
	// 	img {
    //         min-width: 119px;
    //     }
	// }


  &::-webkit-scrollbar {
		display: none; /* For Chrome, Safari, and Opera */
	}
	& > :last-child {
		padding-right: 16px;
	}
	& > :first-child {
		padding-left: 0px;
	}
`;
const singleItem = `
  width: calc(100vw - 32px); /* 100% of the viewport width minus 32px for the margins */
		height: auto; /* maintain aspect ratio */
		// padding: 0 16px;
		// max-height: 343px;
		box-sizing: border-box;
		${border}
		padding-right: 0 !important;
`;
const row = `
  display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px; /* This creates a 16px gap between grid items */
		padding: 0 !important; /* Optional: Adjusts padding around the grid if needed */

		img {
			width: 100%;
			height: auto;
			// max-height: 163.5px;
			${border}
		}
`;
const gridStyles = `
  .grid_for_three {
		${grid} // Gap between grid items
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
		.image_1 {
			grid-column: 1 / span 2; // Span across all columns
			grid-row: 1 / span 2; // Span across two rows
			${largeImageProp}
			width: 324px;
			height: 324px;
            // grid-area: 1 / 1 / 3 / 3;
		}
		.image_2 {
            ${smallImageProp};
			width: 162px;
			height: 162px;
            // grid-area: 1 / 3 / 2 / 4;
        }
		.image_3 {
			 ${smallImageProp};
            // grid-area: 2 / 3 / 3 / 4;
		}

		.image_2 {
			grid-column: 3 / span 1;
   			 grid-row: 1;
			//  width: 118px;
			// height: 118px;
		}

		.image_3 {
			grid-column: 3 / span 1;
    		grid-row: 2;
			//  width: 118px;
			// height: 118px;
		}
	}
  .grid_type_3_A {
		${grid} // Gap between grid items
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
		.image_1 {
			grid-column: 1 / span 2; // Span across all columns
			grid-row: 1 / span 2; // Span across two rows
			${largeImageProp};
		}
		.image_2,
		.image_3 {
			${smallImageProp};
			border-radius: 12px;
		}

		.image_2 {
			grid-column: 1; // First column
			grid-row: 3; // Second row
		}

		.image_3 {
			grid-column: 2; // Second column
			grid-row: 3; // Second row
		}
	}
	.grid_type_3_B {
		${grid}; // Gap between grid items
		.image_3 {
			grid-column: 1 / span 2; // Span across all columns
			grid-row: 2 / span 3; // Span across two rows
			${largeImageProp};
		}
		.image_1,
		.image_2 {
			${smallImageProp};
			border-radius: 12px;
		}

		.image_1 {
			grid-column: 1; // First column
			grid-row: 1; // Second row
		}

		.image_2 {
			grid-column: 2; // Second column
			grid-row: 1; // Second row
		}
	}
	.grid_type_3_C {
		${grid};
		grid-template-columns: repeat(1, 100%); // Gap between grid items
		.image_3 {
			grid-column: 1 / span 2; // Span across all columns
			grid-row: 2 / span 3; // Span across two rows
			${smallImageProp};
		}
		.image_1,
		.image_2,
		.image_3 {
			${smallImageProp};
			border-radius: 12px;
		}

		.image_1 {
			grid-column: 1; // First column
			grid-row: 1; // Second row
		}

		.image_2 {
			grid-column: 1; // Second column
			grid-row: 2; // Second row
		}
		.image_3 {
			grid-column: 1; // Second column
			grid-row: 3; // Second row
		}
	}
	.grid_type_2_A {
		${grid};
		grid-template-columns: repeat(1, 396px);
		// grid-template-rows: repeat(auto-fill, minmax(396px, auto));
		.image_1,
		.image_2 {
			${meidumImageProp};
		}
		.image_1 {
			grid-column: 1; // First column
			grid-row: 1; // Second row
		}

		.image_2 {
			grid-column: 1; // Second column
			grid-row: 2; // Second row
		}
        @media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
            grid-template-columns: repeat(1, 184.5px);
		    // grid-template-rows: repeat(auto-fill, minmax(184.5px, auto));
	    }
	}
	.grid_type_1_A {
		display: grid;
		grid-template-columns: 492px;
		padding-left: 16px;
		.image_1 {
			${singleImageProp}
		}
         @media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
            grid-template-columns: 384px;
	    }
	}
`;
export default React.memo(HorizontalGrid);
