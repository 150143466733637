import { generateEventBuyLink, generateEventTicketAtVenueLink, generateExternalBuyUrl } from "../../helpers/linkGenerators";
import { getBuyButtonText } from "../../helpers/constants";
import { DDEventIds, DDBoxOfficeGoogleMapsLink } from "../../helpers/hardCodedIds";
export const getBuyButtonData = (props) => {
    let { buyButtonHelperText, buyButtonText, disabled } = getBuyButtonText(props.eventState, props.actionText, props.isRsvp, props.communication_strategy);
    let hrefLink, isExternalBuyLink = false;
    if (props.externalBuyUrl) {
        hrefLink = generateExternalBuyUrl(props.externalBuyUrl);
        isExternalBuyLink = true;
    }
    else if (props.eventState === "tickets_at_venue") {
        hrefLink = generateEventTicketAtVenueLink(props.slug);
    }
    else {
        hrefLink = generateEventBuyLink(props.slug, props.platform, props.shouldShowReactBuyPage, props.disableSocialLogin);
    }
    if (DDEventIds.includes(props._id) && props.eventState === "tickets_at_venue") {
        buyButtonText = "BUY AT BOX OFFICE";
        buyButtonHelperText = "Tickets at Box Office are subject to availability";
        hrefLink = DDBoxOfficeGoogleMapsLink;
    }
    buyButtonText = buyButtonText === "BUY" ? "BUY NOW" : buyButtonText;
    return { buyButtonText, buyButtonHelperText, hrefLink, disabled, isExternalBuyLink };
};
