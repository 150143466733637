/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import theme, { colors } from "../styles/common";
import React from "react";
import { generatePassWebflowLink } from "../helpers/linkGenerators";
import { openInBrowserForApp } from "../helpers/appBridge";
const passStyle = `
    padding-right: 8px;
`;
const eventNameStyle = `
    color: white;
    font-size:16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    ${theme.mediaQueries["for-mobile-and-tablet"]}{
        max-width: 90%;
    }
`;
const descStyle = `
    color: white;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
`;
const displayStyle = `
    display: flex;
    border-radius: 9px;
    background-color: ${colors.primaryDark};
    padding: 18px;
    margin-top: 30px;
    cursor: pointer;
    ${theme.mediaQueries["for-mobile-and-tablet"]}{
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-top: 0px;
    };
`;
const iconStyle = `
    display: flex;
    position: absolute;
    right: 18px;
`;
const arrowDirection = (direction) => {
    return direction
        ? `display: none;
            ${theme.mediaQueries["for-mobile-and-tablet"]}{
                display: block;
            }`
        : `display: block;
            ${theme.mediaQueries["for-mobile-and-tablet"]}{
                display: none;
            }`;
};
const knowMoreStyle = `
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    padding-right: 6px;
    color: ${colors.greyBackground};
`;
const InsiderPassLink = (props) => {
    const { prerequisites, isFromApp, event_id } = props;
    const BCIEventId = '6512758067d0d200087885c3';
    const navigateToPass = (eventId, isFromApp = false) => {
        if (props.event_id === BCIEventId) {
            return;
        }
        const passLink = `https://insider.in${generatePassWebflowLink(eventId)}`;
        if (isFromApp) {
            openInBrowserForApp(passLink);
            return;
        }
        window.location.href = generatePassWebflowLink(eventId);
        return;
    };
    return (<React.Fragment>
            <div css={css(displayStyle)} onClick={() => navigateToPass(prerequisites[0].get("eventId"), isFromApp)}>
                {event_id === BCIEventId ?
            <div css={css(passStyle)}>
                        <p css={[css(descStyle), { fontSize: 13 }]}>Please ensure you're logged in with your registered BCI email ID before proceeding.</p>
                    </div>
            :
                <React.Fragment>
                        <div css={css(passStyle)}>
                            {/* [0] because we are going to show only one pass name */}
                            <p css={css(eventNameStyle)}>{prerequisites[0].get("eventName")}</p>
                            <p css={css(descStyle)}>Get amazing benefits with the all new Season passes</p>
                        </div>
                        <div css={css(iconStyle)}>
                            <p css={[css(knowMoreStyle), css(arrowDirection(true))]}>Know more</p>
                            <img src="/assets/images/extras/prerequisite-arrow-up-icon.svg" css={css(arrowDirection(true))}/>
                            <img src="/assets/images/extras/prerequisite-arrow-icon.svg" css={css(arrowDirection(false))}/>
                        </div>
                    </React.Fragment>}
            </div>
        </React.Fragment>);
};
export default InsiderPassLink;
