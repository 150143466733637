import React from "react";
export default class VideoPlayIcon extends React.Component {
    render() {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
				<g fill="none">
					<circle cx="24" cy="24" r="24" fill="#FFF"/>
					<path fill="#000" stroke="#000" strokeLinejoin="round" d="M25.5 16.5L33 31.5 18 31.5z" transform="rotate(90 25.5 24)"/>
				</g>
			</svg>);
    }
}
