/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { openDeepLink } from "../../helpers/h5";
import { isH5Build } from "../../helpers/misc";
import { bodyFontSizes, colors, titleFontSizes } from "../../styles/common";
import { LightBulbIcon } from "../ui/icons/LightBulbIcon";
const TrackOrderDivCss = `
    border-radius: 8px;
    border: 1px solid #e1e3e6;
    padding: 12px;
    background: white;
    margin-top: 18px;
    cursor: pointer;
    display: flex;`;
const TextDiv = `
    padding: 0px 12px;`;
const circleBackgroundDiv = `
    padding: 8px;
    background: ${colors.backgroundBlue};
    border-radius: 50%;
    line-height: 8px;
`;
const iconDiv = `
margin: auto 0;`;
const headingDiv = `
font-weight: 700;
line-height: 150%;
color: ${colors.lightGray};`;
const bodyDiv = `
font-size: ${bodyFontSizes};
color: ${colors.subTextGrey};`;
const paytmTicketsListDeeplink = `paytmmp://mini-app?aId=c16ff022b4bd1378ca02214ec26e41250ed5b542&data=eyJwYXJhbXMiOiIiLCJwYXRoIjoiL2g1bXlvcmRlcnN2dWUvdjEvaW5kZXguaHRtbCMvdHlwZS9tb3ZpZXNldmVudHNfdjIiLCJzcGFyYW1zIjp7InB1bGxSZWZyZXNoIjpmYWxzZSwiY2FuUHVsbERvd24iOmZhbHNlLCJzaG93VGl0bGVCYXIiOmZhbHNlfX0=`;
const navigateToTickets = () => {
    if (isH5Build) {
        return openDeepLink(paytmTicketsListDeeplink);
    }
    else
        return window.location.href = '/users/me/tickets';
};
const TicketDeliveryTracker = () => {
    return <div css={css(TrackOrderDivCss)} onClick={navigateToTickets}>
        <div css={css(iconDiv)}>
            <div css={css(circleBackgroundDiv)}> <LightBulbIcon /></div>
        </div>
        <div css={css(TextDiv)}>
            <p css={[css(titleFontSizes), css(headingDiv)]}>Want to track your ticket status?</p>
            <span css={[css(bodyFontSizes), css(bodyDiv)]}>{isH5Build ? `Open Orders & Bookings -> View ticket` : `Open Profile -> Tickets -> View ticket`}</span>
        </div>
    </div>;
};
export default TicketDeliveryTracker;
