/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import { textMarginSmall } from "../../styles/base/textHelpers";
import { imageContainer } from "../../styles/modules/imageStyles";
export default class PromoBanner extends React.Component {
    render() {
        return (<section css={css(textMarginSmall())} className="text text-left">
				<figure css={css(imageContainer)} className="image-size-big">
					<a href={this.props.toLink}>
						<img src={this.props.imageSrc}/>
					</a>
				</figure>
			</section>);
    }
}
