/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState } from 'react';
import { colors } from "../../Atoms/Typography/styles";
/**
 * Renders an image based on the provided source URL.
 *
 * @param {string} src - The source URL of the image.
 * @param {string} style - Additional CSS styles for the image.
 * @param {string} alt - The alternative text for the image.
 * @param {string} fallbackSrc - The alfallback url if given src url is inavlid.
 * @param {string} fallbackCompo - The alfallback url if given src url is inavlid.
 * @returns {ReactElement} Image component.
 */
const Image = ({ src, style = "", alt = "", fallbackSrc, fallbackCompo }) => {
    const [imgSrc, setImgSrc] = useState(src);
    const [showfallbackCompo, setShowFallbackCompo] = useState(false);
    const handleError = () => {
        if (!fallbackSrc && !fallbackCompo)
            return;
        if (fallbackSrc)
            setImgSrc(fallbackSrc);
        if (fallbackCompo)
            setShowFallbackCompo(true);
    };
    return !showfallbackCompo ? <img src={imgSrc} alt={alt} css={css([imgStyle, style])} onError={handleError}/> : fallbackCompo;
};
export default Image;
const imgStyle = `
    background: ${colors['--Background-BG-SecBTN-Light']};
    display: block;
`;
