export const DDEventIds = [
    "5ae2c568e87c95483ea5f1a8",
    "5aaa0c595165e5180055a50e",
    "5ab8b40aeef9f63056a40752",
    "5ab8b462fe796531a4d986bc",
    "5aaa0a0a507995180002120f",
    "5ab8b46efe796531a4d986ec",
    "5ab8b47afe796531a4d9871c" // DD vs MI
];
export const DDBoxOfficeGoogleMapsLink = "https://goo.gl/maps/WNqcxnhTC2S2";
export const TedXEventId = "5b50918572e26417004448dd";
