import React from "react";
import SummaryCardVisual from "./SummaryCardVisual";
import SummaryCardDetail from "./SummaryCardDetail";
import { NO_DATA_CLASS } from "../helpers/constants";
const RegularSummaryCard = (props) => {
    return (<section className={props.header}>
			<SummaryCardVisual headerImageOrVideo={props.headerImageOrVideo} shellClass={props.title ? "" : NO_DATA_CLASS} shareButtons={props.shareButtons}/>
			<SummaryCardDetail shellClass={props.title ? "" : NO_DATA_CLASS} title={props.title} summary={props.summary} summaryType={props.summaryType} actionButton={props.actionButton} shareButtons={props.shareButtons} categoryName={props.categoryName}/>
		</section>);
};
RegularSummaryCard.defaultProps = {
    categoryColour: ""
};
export default RegularSummaryCard;
