/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import VideoPlayIcon from "./ui/icons/EDPIcons/VideoPlayIcon";
import SummaryCardHeader from "./SummaryCardHeader";
export default class EDPHeaderImageWithVideoEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideo: false
        };
        this.isYoutubeEmbed = (embedHtml) => {
            return /src=".+youtube/.test(embedHtml);
        };
        this.getVideoURL = (embedHtml) => {
            const match = /src="([^"]+)"/g.exec(embedHtml);
            return match != null ? match[1] : null;
        };
        this.getYoutubeVideoUrl = (htmlData) => {
            const video = htmlData
                .filter((data) => this.isYoutubeEmbed(data.get("html")) && this.getVideoURL(data.get("html")) !== null)
                .map((data) => data);
            return video;
        };
        this.playVideo = () => {
            this.setState({ showVideo: true });
        };
        this.getVideoSource = () => {
            return this.getVideoURL(this.getYoutubeVideoUrl(this.props.htmlData).getIn([0, "html"]));
        };
        this.applyYoutubeOptionsToUrl = (embedSrc) => {
            const embedOptions = "modestbranding=1&autoplay=true";
            if (embedSrc.indexOf("?") > -1) {
                return `${embedSrc}&${embedOptions}`;
            }
            return `${embedSrc}?${embedOptions}`;
        };
    }
    render() {
        const { title, headerImageSrc, embedVideo, htmlData } = this.props;
        const FullScreenVideoStyle = `
			width: 100%;
			height: 100%;
		`;
        const playIconStyle = `
			width: 10%;
			position: absolute;
			top: 45%;
            left: 45%;
            cursor: pointer;
		`;
        return (<React.Fragment>
				{!this.state.showVideo ? (<div css={{ position: "relative" }}>
						<SummaryCardHeader headerImage={headerImageSrc} title={title}/>
						{embedVideo && htmlData && this.getYoutubeVideoUrl(htmlData).size > 0 && (<div css={css(playIconStyle)} onClick={this.playVideo}>
								<VideoPlayIcon />
							</div>)}
					</div>) : (<div css={css(FullScreenVideoStyle)}>
						<iframe src={this.applyYoutubeOptionsToUrl(this.getVideoSource())} frameBorder={0} allowFullScreen={true} width="100%" height="100%"/>
					</div>)}
			</React.Fragment>);
    }
}
