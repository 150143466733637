/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import VideoEmbedWithCaption from "./VideoEmbedWithCaption";
import VideoEmbed from "./VideoEmbed";
import { textMarginSmall } from "../styles/base/textHelpers";
export default class VideoCard extends React.PureComponent {
    shouldShowCaption() {
        return this.props.data.getIn(["content", "caption"]).length;
    }
    render() {
        if (this.shouldShowCaption()) {
            return (<section className="video">
					<VideoEmbedWithCaption embedSrc={this.props.data.getIn(["content", "embedsrc"])} coverImage={this.props.data.getIn(["content", "coverImage"])} videoClassName="video-embed" caption={this.props.data.getIn(["content", "caption"])} captionClassName="video-caption" shouldFireClevertapEvent={this.props.shouldFireClevertapEvent} clevertapFireLocation={this.props.clevertapFireLocation} imgWidth={this.props.imgWidth} imgWidths={this.props.imgWidths} sizeString={this.props.sizeString}/>
				</section>);
        }
        return (<section className="video-without-caption" css={css(this.props.addLeftMargin && textMarginSmall())}>
				<VideoEmbed embedSrc={this.props.data.getIn(["content", "embedsrc"])} coverImage={this.props.data.getIn(["content", "coverImage"])} videoClassName="video-embed" shouldFireClevertapEvent={this.props.shouldFireClevertapEvent} clevertapFireLocation={this.props.clevertapFireLocation} imgWidth={this.props.imgWidth} imgWidths={this.props.imgWidths} sizeString={this.props.sizeString}/>
			</section>);
    }
}
