var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { connect } from "react-redux";
import { List } from "immutable";
import { withRouter } from "react-router";
// components
import EventDetailLayout from "../components/EventDetailLayout/EventDetailLayout";
// actions + selectors
import { clearEventData, fetchEventDetails, updateSiteDisplayMode, addToRecentlyViewed, rehydrateReminders, setModalType, setNotifications, clearNotifications, syncUserInfoForPhoenix, fetchTickets, fetchUserToken, checkTicketPurchaseStatus, fetchFeedsActivityData, setReportEventData, fetchRelatedEvents, syncExpressLoginInfo, fetchEventAdBanner } from "../actions";
import { getEventDetails, getDataRequested, getDataReceived, getStreamDataReceived, getStreamDataRequested, getRelatedEvents, getRelatedEventsDataReceived, getRelatedEventsDataRequested, getEventAdBanner, getEventAdBannerDataRequested, getEventAdBannersDataReceived, getAvailableShowsForEvent } from "../selectors/events";
import { getAllCities, getSelectedCity, getShouldShowCitySelector, isFromApp, isUserLoggedIn, getUserToken, getUserId, getPlatform, getDisableSocialLoginFlag } from "../selectors/index";
import { getActiveRecentlyViewedEvents } from "../selectors/recentlyViewed";
import { getLoginDataReceived, getUserProfile } from "../selectors";
import { getTickets, getTicketPurchasedStatusReceived, getTicketPurchasedStatus } from "../selectors/tickets";
const mapStateToProps = (state, ownProps) => {
    return {
        cities: getAllCities(state),
        eventData: getEventDetails(state, ownProps.params.slug),
        isFromApp: isFromApp(state),
        isLoggedIn: isUserLoggedIn(state),
        selectedCity: getSelectedCity(state),
        shouldShowCitySelector: getShouldShowCitySelector(state),
        dataRequested: getDataRequested(state, ownProps.params.slug),
        dataReceived: getDataReceived(state, ownProps.params.slug),
        userProfile: getUserProfile(state),
        didReceiveLoginData: getLoginDataReceived(state),
        userTickets: getTickets(state),
        userToken: getUserToken(state),
        userId: getUserId(state),
        activityDataReceived: getStreamDataReceived(state, ownProps.params.slug),
        activityDataRequested: getStreamDataRequested(state, ownProps.params.slug),
        ticketPurchasedStatusReceived: getTicketPurchasedStatusReceived(state),
        ticketPurchasedStatus: getTicketPurchasedStatus(state),
        platform: getPlatform(state),
        queryParams: ownProps.location.query,
        searchParams: ownProps.location.search,
        recentlyViewedEvents: getActiveRecentlyViewedEvents(state),
        relatedEvents: getRelatedEvents(state, ownProps.params.slug),
        relatedEventsRequested: getRelatedEventsDataRequested(state, ownProps.params.slug),
        relatedEventsReceived: getRelatedEventsDataReceived(state, ownProps.params.slug),
        eventAdBanner: getEventAdBanner(state, ownProps.params.slug),
        eventAdBannerDataRequested: getEventAdBannerDataRequested(state, ownProps.params.slug),
        eventAdBannerDataReceived: getEventAdBannersDataReceived(state, ownProps.params.slug),
        disableSocialLogin: getDisableSocialLoginFlag(state),
        getAvailableShows: getAvailableShowsForEvent(state, ownProps.params.slug)
    };
};
const mapDispatchToProps = (dispatch) => ({
    clearEventData: () => dispatch(clearEventData()),
    fetchEventDetails: ({ slug }) => dispatch(fetchEventDetails({ slug })),
    setModalType: payload => dispatch(setModalType(payload)),
    updateSiteDisplayMode: (displayMode) => dispatch(updateSiteDisplayMode(displayMode)),
    rehydrateReminders: () => dispatch(rehydrateReminders()),
    addToRecentlyViewed: (eventId, eventData) => dispatch(addToRecentlyViewed({
        key: eventId,
        type: "events",
        eventData: eventData
    })),
    notify: (notification) => dispatch(setNotifications(notification)),
    clearNotifications: () => dispatch(clearNotifications()),
    syncUserInfoForPhoenix: (shouldLogin) => dispatch(syncUserInfoForPhoenix(shouldLogin)),
    fetchTickets: () => dispatch(fetchTickets()),
    fetchToken: (eventId, isLoggedIn) => dispatch(fetchUserToken(eventId, isLoggedIn)),
    fetchFeedData: (data, isLoggedIn, slug) => dispatch(fetchFeedsActivityData(data, isLoggedIn, slug)),
    checkTicketPurchase: (eventId) => dispatch(checkTicketPurchaseStatus(eventId)),
    setReportEventData: (eventName, eventURL) => dispatch(setReportEventData({ eventName, eventURL })),
    fetchRelatedEvents: (eventId, city, slug) => dispatch(fetchRelatedEvents(eventId, city, slug)),
    fetchUserInfoForH5: () => dispatch(syncExpressLoginInfo()),
    fetchEventAdBanner: (eventId, city, slug) => dispatch(fetchEventAdBanner(eventId, city, slug))
});
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    let allTagIds;
    const { cities, selectedCity, shouldShowCitySelector } = stateProps, filteredStateProps = __rest(stateProps, ["cities", "selectedCity", "shouldShowCitySelector"]);
    const { eventData } = filteredStateProps;
    if (eventData) {
        allTagIds = eventData.get("tags").map(t => t.get("tag_id"));
    }
    else {
        allTagIds = new List();
    }
    let eventCity;
    if (selectedCity && !shouldShowCitySelector) {
        eventCity = selectedCity;
    }
    else {
        eventCity = cities.find(c => allTagIds.includes(c.get("_id")));
    }
    return Object.assign(Object.assign(Object.assign({ eventCity, selectedCity: selectedCity ? selectedCity.get("name").toLowerCase() : "online" }, dispatchProps), filteredStateProps), ownProps);
};
const EventDetailWithSubscription = withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventDetailLayout));
// set static on this component
// for its preload chunk
EventDetailWithSubscription.preloadChunkName = "EventPage";
EventDetailWithSubscription.cacheString = "public, max-age=30";
export default EventDetailWithSubscription;
