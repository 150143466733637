import React from "react";
export class ShareCrossIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
				<path d="m1 47 46-46m-46 0 46 46" fill="none" stroke="#373435" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
			</svg>);
    }
}
