import React from "react";
import { addMediaType, mediaFeatures } from "../styles/common";
import Media from "react-media";
export const withMediaDesktopOnly = (WrappedComponent) => {
    return (...props) => {
        return (<Media query={`${addMediaType} ${mediaFeatures["for-tablet"]}`}>
				<WrappedComponent {...props}/>
			</Media>);
    };
};
export const withMediaMobileOnly = (WrappedComponent) => {
    return (...props) => {
        return (<Media query={`${addMediaType} ${mediaFeatures["for-mobile"]}`}>
				<WrappedComponent {...props}/>
			</Media>);
    };
};
